export const de = {
    dashboard: 'Dashboard',
    items_selected: 'ausgewählt',
    menu: 'Menu',
    back: 'Zurück',
    edit: 'Bearbeiten',
    create: 'Erstellen',
    create_and_view: 'Erstellen & ansehen',
    create_and_edit: 'Erstellen & bearbeiten',
    filters: 'Filter',
    clear: 'Zurücksetzen',
    update: 'Speichern',
    close: 'Schließen',
    apply: 'Anwenden',
    proceed: 'Fortfahren',
    delete: 'Löschen',
    actions: 'Aktionen',
    cancel: 'Abbrechen',
    fill: 'Ausfüllen',
    description: 'Beschreibung',
    platform_name: 'Plattform Name',
    sub_platform_name: 'Sub Plattform Name',
    parent_platform_name: 'Übergeordnete Plattform',
    platform: 'Plattform',
    platforms: 'Plattformen',
    platform_uid: 'Plattform ID',
    create_platform: 'Plattform erstellen',
    url: 'URL',
    summary: 'Zusammenfassung',
    clearing_system: 'Investmentprodukt',
    clearing_systems: 'Investmentprodukte',
    clearing_system_ci: 'Crowdinvesting (CI)',
    clearing_system_cf: 'Crowdfunding (CF)',
    clearing_system_emission: 'Emission (EMISSION)',
    terms: 'Geschäftsbedingungen',
    privacy_policy: 'Datenschutzbestimmungen',
    platform_attachments: 'Plattformdokumente Vorvertragliche Informationen',
    select_all: 'Alle auswählen',
    id: 'ID',
    name: 'Name',
    type: 'Typ',
    file: 'Datei',
    choose_file: 'Datei auswählen',
    choose_image: 'Bild auswählen',
    reset: 'Zurücksetzen',
    information_letter: 'Informationsschreiben',
    platform_webhooks: 'Plattform Webhooks',
    platform_webhook: 'Platform Webhook',
    event: 'Event',
    secret: 'Secret',
    platform_policies: 'Plattform Access-Richtlinien ',
    platform_policy: 'Platform Access-Richtlinie',
    country: 'Land',
    countries: 'Länder',
    language: 'Sprache',
    content: 'Inhalt',
    platform_email_templates: 'Plattform E-Mail Vorlagen',
    subject: 'Betreff',
    placeholders: 'Platzhalter',
    insert: 'Einfügen:',
    platform_disclaimer: 'Plattform Risikowarnungen',
    platform_disclaimers: 'Plattform Risikowarnungen',
    countries_applied: 'Angewendet in',
    countries_not_applied: 'Nicht angewendet in',
    platform_details: 'Plattform Details',
    platform_logo: 'Logo',
    links: 'Links',
    privacy_url: 'Datenschutzerklärung',
    email_settings: 'E-Mail Einstellungen',
    admin_email: 'Admin E-Mail',
    mail_template: 'E-Mail Vorlage',
    order_status_for_total_funding_sum: 'Investitionssumme',
    pending: 'Ausstehend',
    paid: 'Bezahlt',
    paid_total_gross: 'Bezahlt - Brutto',
    paid_total_net: 'Bezahlt - Netto',
    total_tax: 'Steuer gesamt',
    church_tax: 'Kirchensteuer',
    soli: 'Soli',
    tax: 'Steuer',
    accepted: 'Akzeptiert',
    smtp_settings: 'SMTP Einstellungen',
    mail_host: 'SMTP Server',
    mail_port: 'SMTP Port',
    mail_encryption: 'Verschlüsselung',
    none: 'Keine',
    mail_from_address: 'Absender E-Mail',
    mail_form_name: 'Absender Name',
    mail_username: 'SMTP Username',
    mail_password: 'SMTP Passwort',
    assets: 'Vermögenswerte',
    asset: 'Vermögenswert',
    asset_name: 'Vermögenswert',
    currency: 'Währung',
    create_asset: 'Vermögenswert erstellen',
    asset_title: 'Titel',
    asset_details: 'Details zum Vermögenswert',
    asset_category: 'Anlagekategorie',
    asset_location: 'Standort des Vermögenswerts',
    asset_location_state: 'Bundesland',
    asset_location_country: 'Land',
    asset_funding_limits: 'Finanzierungs Limits',
    asset_funding_min: 'Finanzierungsschwelle',
    asset_funding_max: 'Finanzierungsmaximum',
    asset_investment_min: 'Minimales Investment',
    asset_investment_max: 'Maximales Investment',
    asset_step_level: 'Finanzierungsschritte',
    asset_base_funding_count: 'Basis Investitionen',
    asset_base_funding_total: 'Basis Investitionssumme',
    manual_funding_totals: 'Manuelle Investitionssummen',
    asset_manual_funding_totals: 'Manuelle Investitionssummen',
    automatic_payments: 'Automatische Zahlungsabwicklung',
    asset_deactivate_payment_account:
        'Deaktivieren der automatischen Zahlungsabwicklung',
    asset_documents: 'Dokumente des Vermögenswert',
    asset_documents_0: 'Vertragsdaten',
    asset_documents_1: 'Reporting',
    asset_documents_2: 'Verbraucherinformation',
    company_details: 'Firmen Daten',
    company_name: 'Firmen Name',
    company_logo: 'Logo',
    address: 'Adresse',
    city: 'Stadt',
    postal_code: 'Postleitzahl',
    state: 'Bundesland/Kanton',
    phone_number: 'Telefonnummer',
    email: 'E-Mail',
    company_website: 'Firmen Webseite',
    iban: 'IBAN',
    bic: 'BIC',
    contract: 'Vertrag',
    contracts: 'Verträge',
    contract_details: 'Vertragsdaten',
    contract_range: 'Vertragslaufzeit',
    interest_rate: 'Zinssatz',
    signatures: 'Zeichnungen',
    signature_type: 'Zeichnungsart',
    signature_types: 'Zeichnungsarten',
    signature_type_manual: 'Manual',
    signature_type_sproof: 'Sproof',
    asset_signature_legal_notice: 'Zeichnungshinweis',
    contract_type: 'Vertragsart',
    contract_type_cash: 'Geld',
    contract_type_voucher: 'Gutschein',
    contract_payment_start_date: 'Erste Auszahlung',
    contract_next_payment_date: 'Nächste Auszahlung',
    payment_date: 'Payment Date',
    contract_period_type_fixed: 'Fixer Vertrag',
    asset_document_type_0: 'Vertragsdaten',
    asset_document_type_1: 'Reporting',
    asset_document_type_2: 'Verbraucherinformation',
    asset_signature_document: 'Emission Zeichnungsschein',
    asset_signature_document_name: 'Emission Zeichnungsschein Name',
    help_asset_signature_document_name: 'Der Emission Zeichnungsschein Name',
    asset_signature_document_description:
        'Emission Zeichnungsschein Beschreibung',
    help_asset_signature_document_description:
        'Die Emission Zeichnungsschein Beschreibung',
    transfer: 'Überweisen',
    wallet: 'Wallet',
    amount: 'Betrag',
    date: 'Datum',
    associate_orders: 'Bestellungen verknüpfen',
    campaign: 'Kampagne',
    campaigns: 'Kampagnen',
    order: 'Bestellung',
    orders: 'Bestellungen',
    open_baskets: 'offene Bestellungen',
    basket_stage: 'aktueller Schritt',
    view_campaigns: 'Anzeigen',
    status: 'Status',
    sub_status: 'Substatus',
    created_on: 'Erstellt am',
    campaign_title: 'Titel der Kampagne',
    campaign_subtitle: 'Untertitel der Kampagne',
    create_campaign: 'Neue Kampagne anlegen',
    campaign_details: 'Kampagnendetails',
    campaign_summary: 'Zusammenfassung',
    campaign_excerpt: 'Auszug',
    funding_period: 'Finanzierungszeitraum',
    funding_start: 'Finanzierungsbeginn',
    funding_end: 'Finanzierungsende',
    regulatory_requirements: 'Regulatorische Anforderungen',
    agio_disagio: 'Agio/Disagio',
    agio_disagio_title: 'Agio/Disagio Titel',
    agio_disagio_rate: 'Agio/Disagio Rate',
    agio_disagio_type: 'Agio/Disagio Typ',
    agio_disagio_info_text: 'Agio/Disagio Infotext',
    agio_disagio_disclaimer: 'Agio/Disagio Bedingungen',
    agio_disagio_voucher: 'Agio/Disagio Gutschein',
    savings_plan: 'Ansparplan',
    savings_plan_description: 'Ansparplan Beschreibung',
    voucher_code: 'Gutschein Code',
    voucher_rate: 'Gutschein Rate',
    country_and_language: 'Land und Sprache',
    campaign_status: 'Status',
    funding_stats_visible: 'Finanzierungsstatus anzeigen',
    password: 'Passwort',
    broker: 'Broker',
    broker_status: 'Broker Status',
    required_field: 'Pflichtfeld',
    broker_disclaimer: 'Broker Bedingung',
    external_project_page: 'Externe Projektseite',
    external_project_url: 'Externe Projekt URL',
    badge: 'Plakette',
    campaign_badge: 'Plakette wählen',
    checkout: 'Zahlungsabwicklung',
    show_amount_slider: 'Betragsregler anzeigen',
    media: 'Medien',
    campaign_thumb_image: 'Thumbnail',
    campaign_hero_image: 'Hauptbild',
    campaign_content: 'Inhalt',
    main: 'Main',
    user_orders_list: 'Liste der Bestellungen',
    export_asset_investment_report: 'Investment Report exportieren',
    export_check_orders_report: 'Bestellungskontroll-Report exportieren',
    send_ad_hoc_email_reminder: 'Ad-hoc E-Mail Erinnerung senden',
    transfer_to_project_wallet: 'Auf Projekt Wallet überweisen',
    content_images: 'Bilder',
    loading: 'Laden ...',
    content_blocks: 'Bausteine',
    rank: 'Rang',
    title: 'Title',
    team_member: 'Teammitglied',
    team_members: 'Teammitglieder',
    job_title: 'Job Bezeichnung',
    incentives: 'Anreize',
    funding: 'Finanzierung',
    campaign_content_block_title: 'Name',
    campaign_content_block_content: 'Inhalt',
    campaign_incentive_amount: 'Anreiz Betrag',
    campaign_incentive_description: 'Anreiz Belohnung',
    campaign_incentive_title: 'Anreiz Titel',
    campaign_incentives_limit: 'Anreiz Limit',
    campaign_team_member_name: 'Name',
    campaign_team_member_title: 'Titel',
    campaign_team_member_bio: 'Beschreibung',
    campaign_team_member_image: 'Bild',
    account_title: 'Investor Name',
    wallet_status_title: 'Wallet Status',
    withdrawals: 'Abhebungen',
    withdrawal_amount: 'Summe',
    wallet_money_withdrawals: 'Abhebungen von Wallet',
    wallet_status_sc: 'SC Wallet',
    wallet_status_not_opened: 'Nicht eröffnet',
    wallet_status_incomplete: 'Unvollständig',
    wallet_status_rejected: 'Abgelehnt',
    wallet_status_kyc1: 'KYC 1',
    wallet_status_kyc2: 'KYC 2',
    wallet_status_kyc3: 'KYC 3',
    wallet_status_expired: 'Abgelaufen',
    wallet_status_blocked: 'Blockiert',
    wallet_status_closed: 'Geschlossen',
    wallet_status_kyc_updating_2_3: 'Updating von KYC 2 zu 3',
    wallet_status_ont_time_customer: 'Einmaliger Kunde',
    wallet_status_crowdlending: 'Crowdlending Wallet',
    wallet_status_technique: 'Technique Wallet',
    wallet_status_undefined: 'Konto hat keine Wallet',
    wallet_withdrawal_started_from_frontend: 'Im Frontend gestartet',
    wallet_transactions: 'Wallet Transaktionen',
    no_wallet_transactions: 'keine Wallet Transaktionen',
    transaction_sender: 'Absender',
    transaction_receiver: 'Empfänger',
    transaction_type: 'Transaktionstyp',
    transactions: 'Transaktionen',
    account: 'Investor',
    accounts: 'Investoren',
    natural_profile_active: 'Aktiv',
    account_first_name: 'Vorname',
    account_last_name: 'Nachname',
    account_email: 'E-Mail',
    account_date_of_birth: 'Geburtsdatum',
    account_nationality: 'Nationalität',
    account_default_language: 'Standardsprache',
    account_email_pending: 'E-Mail ausstehend',
    linked_natural_profiles: 'Natürliche Personen',
    natural_profiles: 'Natürliche Personen',
    male: 'männlich',
    female: 'weiblich',
    account_gender: 'Geschlecht',
    account_custom_id: 'Individuelle ID',
    account_pep: 'Politisch exponierte Person (PEP)',
    account_is_pep: 'Ja',
    account_is_not_pep: 'Nein',
    account_nl_subscribed: 'Newsletter zugestimmt bei Registrierung',
    account_nl_is_subscribed: 'Ja',
    account_nl_is_not_subscribed: 'Nein',
    account_address: 'Adresse',
    account_address_required_for_wallet: 'Adresse (Verpflichtend für Wallet)',
    account_zip: 'PLZ',
    account_zip_required_for_wallet: 'PLZ (Verpflichtend für Wallet)',
    account_country: 'Land',
    account_country_required_for_wallet: 'Land (Verpflichtend für Wallet)',
    account_city: 'Stadt',
    account_city_required_for_wallet: 'Stadt (Verpflichtend für Wallet)',
    account_state: 'Bundesland',
    account_state_required_for_wallet: 'Bundesland (Verpflichtend für Wallet)',
    account_phone: 'Telefon',
    account_mobile: 'Mobiltelefon',
    account_primary_email: 'E-Mail',
    account_pending_email: 'Unbestätigte E-Mail Adresse',
    account_address_kyc_status: 'Adressangaben - KYC-Status',
    account_address_kyc_document: 'KYC Dokumente - Adresse',
    wallet_not_yet_created: 'Keine Wallet vorhanden',
    account_kyc_not_verified: 'KYC noch nicht bestätigt',
    kyc_bank_documents: 'KYC Dokumente - Bankkonto',
    kyc_tax_documents: 'KYC Dokumente - Steuerdaten',
    social_security_number: 'Nationale Steuernummer',
    account_kyc_status: 'Identitätsangaben - KYC-Status',
    account_iban: 'IBAN',
    account_bic: 'BIC',
    account_bank_accounts: 'Bankkonten',
    account_bank_account: 'Bankkonto',
    account_bank_account_headline: 'Liste der Bankkonten',
    bank_accounts_list: 'Liste der Bankkonten',
    no_bank_accounts: 'es gibt keine Bankkonten',
    is_main_bank_account: 'Hauptkonto',
    account_bank_historical_data: 'historische Daten (ohne Lemonway)',
    account_owner: 'Inhaber',
    account_holder: 'Bankkontoinhaber',
    account_type: 'Typ',
    account_status: 'Status',
    created_at: 'Erstellt',
    cancelled_at: 'Abgebrochen',
    account_bank_kyc_status: 'Bankkonto KYC-Status',
    account_profile_overview: 'Profilübersicht',
    account_profile_details: 'Profildetails',
    account_address_contact_details: 'Adresse und Kontaktdaten',
    account_address_details: 'Addresse',
    account_identity_details: 'Identitätsdetails',
    button_delete_dsgvo: 'DSGVO Daten löschen',
    create_natural_account: 'natürliche Person erstellen',
    profile_company_name: 'Firmenname',
    profile_company_company_email: 'Firmen E-Mail',
    profile_company_description: 'Unternehmensgegenstand',
    profile_company_required_for_wallet: 'Benötigt für Wallet',
    profile_company_registration_number: 'Unternehmenregistrationsnummer',
    profile_company_website_url: 'Webseite',
    profile_company_details_kyc: 'Prüfung der Unternehmensdaten  (KYC)',
    profile_company_bank_kyc: 'Prüfung der Bankverbindung (KYC)',
    legal_account_create: 'Geschäftskonto anlegen',
    legal_account_show: 'Geschäftskonto anzeigen',
    contact_person: 'Kontaktperson',
    address_details: 'Adressdetails',
    bank_details: 'Bank Details',
    tax_details: 'Steuer Details',
    wallet_details: 'Wallet Details',
    kyc_documents: 'KYC Dokumente',
    kyc_id_documents: 'KYC Dokumente zur Prüfung der Identität',
    kyc_legal_documents: 'KYC Dokumente zur Prüfung der Unternehmensdaten',
    kyc_document_no_comment: 'Kein Dokument vorhanden',
    open_kyc_document: 'öffnen',
    kyc: 'KYC',
    kyc_external: 'Externes KYC',
    external_kyc_details: 'Externes KYC Details',
    external_kyc_process_status: 'Status externer KYC',
    kyc_external_id: 'ID',
    kyc_external_external_id: 'Externe KYC-ID',
    kyc_external_status: 'KYC Status',
    kyc_external_created_at: 'Erstellt am',
    kyc_external_updated_at: 'Zuletzt geändert am',
    kyc_external_natural_profile_overwritten_at:
        'Investorenkonto überschrieben am',
    kyc_external_result_summary_url: 'Zusammenfassung herunterladen',
    kyc_external_result_data_package_url: 'Datenpaket herunterladen',
    button_kyc_reset_external_process: 'Externe KYC zurücksetzen',
    comment: 'Kommentar',
    document_id: 'Dokument ID',
    verification_type: 'Verifikationsart',
    verification_status: 'Verifikationsstatus',
    account_id: 'Acocunt ID',
    valid_to: 'Gültig bis',
    kyc_verification_status: 'Verifizierungsstatus',
    verification_date: 'Verifizierungsdatum',
    external_kyc: 'externes KYC',
    manual_kyc: 'manuelles KYC',
    open_doc_in_new_tab: 'Dokument in neuem Tab öffnen',
    kyc_document_file: 'KYC Dokument - File',
    kyc_type_kyc_id: 'Prüfung der Identität',
    kyc_type_kyc_bank: 'Prüfung der Bankverbindung1',
    kyc_type_kyc_address: 'Prüfung der Adressangaben',
    kyc_type_kyc_tax: 'Prüfung der Steuerdetails',
    kyc_address_documents: 'KYC Dokumente - Adresse',
    tax_number: 'Steuernummer',
    account_social_security_number: 'Sozialversicherungsnummer',
    account_tax_kyc_status: 'Steuerdetails - KYC-Status',
    account_show_legal_account: 'Zeige juristische Person',
    profile: 'Profil',
    view_profile: 'Profil ansehen',
    wallet_balance: 'Saldo',
    wallet_retrievable_balance: 'verfügbarer Saldo',
    wallet_blocked_balance_from_paid_orders: 'blockiert (bezahlte Order)',
    wallet_blocked_balance_from_pending_withdrawals:
        'blockiert (ausstehende Auszahlungen)',
    wallet_status: 'Wallet Status',
    wallet_currency: 'Währung',
    wallet_deposit_information: 'Einzahlungsinformationen',
    wallet_deposit_account_holder: 'Kontoinhaber',
    wallet_deposit_iban: 'IBAN',
    wallet_deposit_bic: 'BIC',
    wallet_deposit_payment_reference: 'Zahlungsreferenz',
    money_withdrawal_value: 'Summe der Abhebung',
    yes: 'ja',
    no: 'nein',
    search_account_title: 'Investor suchen',
    nothing_found: 'Nichts gefunden',
    search_other_user: 'Anderen Investor suchen',
    order_send_mails: 'Investitionsmails senden',
    date_of_order: 'Investitionsdatum',
    order_attachment_type: 'Order attachment type',
    order_attachment_name: ' Order attachment name',
    order_attachment_description: 'Order attachment description',
    edit_order: 'Bestellung bearbeiten',
    additional_fields: 'Zusätzliche Felder',
    order_attachments: 'Anhänge',
    order_attachment_upload: 'Anhang hochladen',
    order_documents: 'Bestellungs Dokumente',
    reporting_documents: 'Reporting Dokumente',
    documents: 'Dokumente',
    view_document: 'Dokument anzeigen',
    depot: 'Depot',
    depot_owner: 'Depotinhaber',
    depot_number: 'Depotnummer',
    depot_bank: 'Depotbank',
    depot_bic: 'Depotbank BIC',
    order_details: 'Bestelldetails',
    order_id: 'ID',
    order_emission_signature: 'Zeichnung',
    order_emission_signatures: 'Zeichnungen',
    document_type: 'Dokumentenart',
    signature_date: 'Signature date',
    signature_cancellation_date: 'Abbruchdatum der Zeichnung',
    confirm_manual_signature: 'Bestätigen der Manuellen Zeichnung',
    regenerate_signatures: 'Zeichnungen regenerieren',
    account_name: 'Investor Name',
    change_order_status: 'Bestellstatus ändern',
    bulk_change_order_status: 'Bestellstatus mehrerer Bestellungen ändern',
    total: 'Total',
    invested_amount: 'Investierter Betrag',
    wallet_amount: 'Wallet-Betrag',
    total_amount_to_pay: 'zu bezahlender Betrag',
    incentive: 'Anreiz',
    voucher_amount: 'Voucher-Betrag',
    voucher_balance: 'Voucher-Saldo',
    vouchers: 'Gutscheine',
    reference_number: 'Referenz',
    investor_information_for_germany: 'Kundeninformationen für Deutschland',
    please_confirm_data: 'Bestätigung',
    first_name: 'Vorname',
    last_name: 'Nachname',
    place_of_birth: 'Geburtsort',
    id_card_passport_number: 'Personalausweisnummer oder Reisepass',
    issuing_authority: 'Ausstellende Behörde',
    vib_investment_information:
        'Hiermit bestätige ich, dass ich das Vermögensanlagen-Informationsblatt rechtzeitig zur Verfügung gestellt bekommen sowie heruntergeladen und gespeichert habe. Insbesondere habe ich den Warnhinweis, dass der Erwerb dieser Vermögensanlage mit erheblichen Risiken verbunden ist und bis zum vollständigen Verlust des eingesetzten Vermögens führen kann, zur Kenntnis genommen.',
    vib_investment_under_1000:
        'Hiermit bestätige ich, dass der Gesamtbetrag der von mir erworbenen Vermögensanlagen der „synthesize B2C markets (EM)“ (inklusive meines im Rahmen dieses Investmentangebots gewährten Darlehensbetrages) 1000 Euro nicht übersteigt',
    vib_investment_income:
        'Hiermit bestätige ich, dass der Gesamtbetrag der von mir erworbenen Vermögensanlagen der synthesize B2C markets (EM) (inklusive meines im Rahmen dieses Investmentangebots gewährten Darlehensbetrages) zwischen 10 000 und 25 000 Euro liegt, jedoch den zweifachen Betrag meines durchschnittlichen monatlichen Nettoeinkommens nicht übersteigt.',
    vib_investment_financial_assets_over_10000:
        'Hiermit bestätige ich, dass der Gesamtbetrag der von mir erworbenen Vermögensanlagen der „synthesize B2C markets (EM)“ (inklusive meines im Rahmen dieses Investmentangebots gewährten Darlehensbetrages) zwischen 1 000 und 10 000 Euro liegt und ich über ein frei verfügbares Vermögen in Form von Bankguthaben und Finanzinstrumenten von mindestens 100 000 Euro verfüge.',
    vib_investment_limit:
        'Hiermit bestätige ich, dass ich insgesamt nicht mehr als 25.000 € an Vermögensanlagen des Emittenten „synthesize B2C markets (EM)“ erworben habe (inklusive meines im Rahmen dieses Investmentangebots gewählten Darlehensbetrags).',
    wphg_investment_under_1000:
        'Hiermit bestätige ich, dass meine Investition 1.000 € nicht übersteigt.',
    wphg_investment_limit:
        ' Hiermit bestätige ich, dass ich insgesamt nicht mehr als 25.000 € in Wertpapiere des Emittenten synthesize B2C markets (EM) investiert habe (inklusive meines im Rahmen dieses Angebots gewählten Investments).',
    wphg_inappropriate_investment:
        'Ich bin darüber informiert, dass die Zeichnung dieses Produkts nicht meinem Anlageprofil aufgrund der von mir gemachten Angaben entspricht. Ich entscheide mich dennoch für die Zeichnung dieses Produkts.',
    order_was_created_manually: 'Wurde manuell erstellt',
    profile_information_legal: 'Investor (Unternehmensprofil)',
    profile_legal: 'Unternehmensprofil',
    profile_information_natural: 'Investor (natürliche Person)',
    linked_profile_information_natural: 'Verknüpfte natürliche Person',
    date_of_birth: 'Geburtsdatum',
    nationality: 'Nationalität',
    change_status_to: 'Status ändern zu',
    for_selected_orders: 'für die Bestellungen {selected}',
    account_name_at_order: 'Investor Name bei Bestellung',
    account_name_current: 'Aktueller Investor Name',
    set_to_canceled: 'Bestellung abbrechen',
    set_to_paid: 'Auf bezahlt setzen',
    set_to_pending: 'Auf ausstehend setzen',
    set_to_accepted: 'Auf akzeptiert setzen',
    investment: 'Investment',
    bullet: 'Bullet',
    period: 'Zeitraum',
    next_payment: 'Nächste Auszahlung',
    interest: 'Zinsen',
    payouts: 'Auszahlungen',
    contract_duration: 'Vertragslaufzeit',
    first_payout: 'Erste Auszahlung',
    last_payout: 'Letzte Auszahlung',
    total_payments_count: 'Anzahl der Auszahlungen',
    start_of_contract: 'Start der Laufzeit',
    add_date: 'Auszahlungsdatum hinzufügen',
    early_bird_promotion: 'Early Bird Promotion',
    early_bird_interest_rate: 'Early Bird Zinssatz',
    early_bird_deadline: 'Early Bird Deadline',
    interest_rate_calculation: 'Zinssatzberechnung',
    calculation_method: 'Methode zur Berechnung',
    calculation_start_date: 'Berechnungsbeginn',
    repayment: 'Zurückzahlung',
    bullet_loan: 'Bullet Loan',
    deferred_clearance: 'Tilgung aufschieben',
    tax_calculation: 'Steuerberechnung',
    tax_calculation_enabled: 'Steuerberechnung aktiviert',
    external_wallet: 'External Wallet',
    external_wallet_id: 'External Wallet ID',
    wallet_id: 'Wallet ID',
    voucherify_settings: 'Voucherify Einstellungen',
    voucher_wallet: 'Gutschein Wallet',
    voucher_campaign: 'Gutschein Kampagne',
    voucher_campaigns: 'Gutschein Kampagnen',
    voucher_campaign_name: 'Kampagnen Name',
    add_voucher_campaign: 'Kampagne hinzufügen',
    voucher_campaign_type: 'Campaign Typ',
    voucher_campaign_default: 'Standard',
    voucher_contract: 'Gutschein Vertrag',
    interest_payments: 'Zinszahlungen',
    paid_interest: 'Ausgezahlte Zinsen',
    paid_clearance: 'Tilgung',
    payment: 'Auszahlung',
    total_amount: 'Gesamtsumme',
    sum_paid_clearance: 'Tilgung',
    bonus: 'Bonus',
    download_sepa_csv: 'SEPA CSV herunterladen',
    start_payment: 'Auszahlung starten',
    paid_at: 'Bezahlt am',
    accepted_at: 'Akzeptiert am',
    interest_payment_transactions: 'Zinszahlungen',
    loan: 'Darlehn',
    clearance: 'Tilgung',
    rest: 'Rest',
    execute_interest_payment: 'Zinszahlung ausführen',
    execute_interest_payment_including_sepa_export:
        'Zinszahlung ausführen und SEPA-CSV exportieren',
    recalculate: 'Neuberechnen',
    recalculate_and_export_excel: 'Neuberechnen und Excel exportieren',
    recalculate_and_export_sepa_csv: 'Neuberechnen und SEPA-CSV exportieren',
    please_recalculate_interest_first: 'Bitte berechnen Sie zuerst die Zinsen.',
    sum_paid_rest: 'Summe ausgezahlter Zinsen',
    pay_interest: 'Zinsen auszahlen',
    pay_clearance: 'Tilgung auszahlen',
    pay_percent_of_rest: 'Prozentanteil des Restbetrags auszahlen',
    interest_bonus: 'Bonuszinsen',
    new_calculation: 'Neue Berechnung',
    load_payout_tasks: 'Auszahlungen laden',
    start_interest_payment_payout: 'Neuen Auszahlungs-PayoutTask starten',
    started_at: 'Gestartet am',
    finished_at: 'Abgeschlossen am',
    finished_type: 'Ergebnis Typ',
    result: 'Ergebnis',
    result_details: 'Ergebnis Details',
    interest_payment_date: 'Auszahlungsdatum',
    settings: 'Einstellungen',
    sign_out: 'Abmelden',
    export_accounts_csv: 'Gefilterte Investoren-Liste exportieren',
    view: 'Ansehen',
    no_table_entries: 'Keine Einträge vorhanden.',
    check_table_filters: 'Filter anpassen.',
    export_orders_csv: 'Gefilterte Liste exportieren',
    bulk_change_order_campaign: 'Kampagne ändern',
    natural_account: 'Natürliche Person',
    legal_account: 'Juristische Person',
    active: 'Aktiv',
    no_orders: 'Keine Bestellungen vorhanden.',
    manual_investments: 'Manuelle Investmentsumme',
    duplicate: 'Duplizieren',
    change_status: 'Status ändern',
    change_order_status_date: 'Status-Datum ändern',
    emails: 'E-Mails',
    email_template: 'E-Mail Vorlage',
    recipient: 'Empfänger',
    is_test: 'Test',
    send: 'Senden',

    button_bank_kyc_document_upload: 'Bank-Nachweishochladen',
    button_address_kyc_document_upload: 'Adress-Nachweis hochladen',
    button_tax_kyc_document_upload: 'Steuerdaten-Nachweis hochladen',
    button_id_kyc_document_upload: 'ID-Nachweis hochladen',
    button_legal_kyc_document_upload: 'Unternehmensdaten-Dokument hochladen',
    button_sync_wallet: 'Wallet synchronisieren',
    button_delete_legal: 'Account löschen',
    sender: 'Absender',
    receiver: 'Empfänger',
    transaction_method: 'Transaktionsart',
    date_from: 'Datum von',
    date_to: 'Datum bis',
    view_linked_order: 'Zugehörige Bestellung anzeigen',
    view_sender_natural_profile: 'Natürliche Person (Absender) anzeigen',
    view_receiver_natural_profile: 'Natürliche Person (Empfänger) anzeigen',
    view_sender_legal_profile: 'Unternehmensprofil (Absender) anzeigen',
    view_receiver_legal_profile: 'Unternehmensprofil (Empfänger) anzeigen',
    no_signatures: 'Keine Zeichnungen',
    billing_address: 'Rechnungsadresse',
    postal_address: 'Zustelladresse',
    contact_details: 'Kontaktdetails',
    same_as_billing_address: 'Gleich wie Rechnungsadresse',

    help_platform_name: 'Der Name der Plattform.',
    help_parent_platform_name: 'Die übergeordnete Plattform.',
    help_platform_url: 'Die URL unter der die Plattform erreichbar ist.',
    help_file_name: 'Der Name der Datei.',
    help_file_type: 'Der Typ der Datei.',
    help_platform_webhook_event: 'Das Event, welches den Webhook auslöst.',
    help_platform_webhook_url:
        'Die URL, die aufgerufen wird wenn das Event den Webhook auslöst.',
    help_platform_webhook_secret:
        'Der Sicherheitsschlüssel mit dem der Webhook abgesichert ist. Der Sicherheitsschlüssel ist nur während der Erstellung einsehbar.',
    help_platform_policy_name: 'Der Name der Plattform Access-Richtlinie.',
    help_platform_policy_country:
        'Das Land in dem die Plattform Access-Richtlinie gültig ist.',
    help_platform_policy_language:
        'Die Sprache in der die Plattform Access-Richtlinie verfasst wurde.',
    help_platform_policy_content: 'Der Inhalt der Plattform Access-Richtlinie.',
    help_platform_email_template_type: 'Der Typ der Plattform E-Mail Vorlage.',
    help_platform_email_template_language:
        'Die Sprache in der die E-Mail Vorlage verfasst wurde.',
    help_platform_email_template_subject: 'Der Betreff der E-Mail Vorlage.',
    help_platform_email_template_content:
        'Der Inhalt der E-Mail Vorlage. Um die Platzhalter neu zu laden, bitte erst den Typ zurücksetzen und dann neu auswählen.',
    help_platform_disclaimer_name: 'Der Name der Risikowarnung.',
    help_platform_disclaimer_language:
        'Die Sprache in welcher der Inhalt der Warnung verfasst wurde.',
    help_platform_disclaimers_countries_applied:
        'Die Ländern, in denen diese Warnung angewendet wird.',
    help_platform_disclaimers_countries_not_applied:
        'Die Ländern, in denen diese Warnung NICHT angewendet wird..',
    help_platform_disclaimer_content: 'Der Inhalt der Plattform Warnung.',
    help_platform_terms:
        'Öffentlich zugängliche URL zu den Geschäftsbedingungen.',
    help_platform_privacy_url:
        'Öffentlich zugängliche URL zur Datenschutzerklärung.',
    help_platform_admin_email:
        'Die E-Mail Adresse, welche zum versenden von E-Mails verwendet wird.',
    help_platform_mail_template:
        'E-Mail Vorlage, welche zum versenden von E-Mails verwendet wird.',
    help_clearing_systems: 'Wählen Sie eines oder mehrere Investitionsvehikel.',
    help_clearing_system: 'Wählen Sie ein Investitionsvehikel.',
    help_order_status_for_total_funding_sum:
        'Investitionsstatus zur Berechnung der gesamten Investitionssumme.',
    help_mail_host:
        'Der SMTP Server, welcher zum versenden von E-Mails verwendet wird.',
    help_mail_port:
        'Der Server Port, welcher verwendet wird um mit dem SMTP Server zu kommunizieren.',
    help_mail_encryption:
        'Die Verschlüsselungsmethode, welche verwendet wird um mit dem SMTP Server zu kommunizieren.',
    help_mail_from_address:
        'Die Absender E-Mail Adresse, welche beim versenden von E-Mails verwendet wird.',
    help_mail_form_name:
        'Der Absender Name, welcher beim versenden von E-Mails verwendet wird.',
    help_mail_username:
        'Der Benutzername, welcher bei der Authentifizierung gegenüber dem SMTP Server angegeben wird.',
    help_mail_password:
        'Das Passwort, welches bei der Authentifizierung gegenüber dem SMTP Server angegeben wird.',
    help_asset_title: 'Der Titel des Vermögenswerts.',
    help_currency: 'Die zu verwendende Währung.',
    help_asset_base_funding_count:
        'Erhöhen Sie die Anzahl der Investments manuell.',
    help_asset_base_funding_total:
        'Erhöhen Sie den investierten Betrag manuell.',
    help_asset_category: 'Die Kategorie des Vermögenswerts.',
    help_asset_currency: 'Die Währung des Vermögenswerts.',
    help_asset_deactivate_payment_account:
        'Sollen automatische Zahlungsabwicklungen deaktiviert sein?',
    help_asset_funding_min: 'Die Finanzierungsschwelle des Vermögenswerts.',
    help_asset_funding_max: 'Das Finanzierungsmaximum des Vermögenswerts.',
    help_asset_investment_min: 'Das minimale Investment.',
    help_asset_investment_max: 'Das maximale Investment.',
    help_asset_location_city: 'Die Stadt des Vermögenswert.',
    help_asset_location_state: 'Das Bundesland des Vermögenswerts.',
    help_asset_location_country: 'Das Land des Vermögenswerts.',
    help_asset_manual_funding_totals:
        'Sollen manuelle Investitionssummen angezeigt werden?',
    help_asset_status: 'Haptstatus.',
    help_asset_step_level: 'Die Finanzierungsschritte der Investition.',
    help_asset_sub_status: 'Substatus.',
    help_company_name: 'Der Name der Firma.',
    help_company_logo:
        'Das Logo der Firma, welches im Frontend angezeigt wird.',
    help_company_address: 'Die Adresse von der aus die Firma operiert.',
    help_company_city:
        'Die Stadt oder Ortschaft von der aus die Firma operiert.',
    help_company_postal_code:
        'Die Postleitzahl der Ortschaft von der aus die Firma operiert.',
    help_company_state: 'Das Bundesland/Kanton von der aus die Firma operiert.',
    help_company_country: 'Das Land von dem aus die Firma operiert.',
    help_company_phone_number: 'Die Telefonnummer der Firma.',
    help_company_email: 'Firmen E-Mail Adresse.',
    help_company_website: 'Die Webseite der Firma.',
    help_company_iban: 'Der IBAN der Firma.',
    help_company_bic: 'Der BIC der Firma.',
    help_contract_range: 'Bspw. 3 Jahre, 6 Monate, ...',
    help_interest_rate: 'Der Zinssatz des Vertrags.',
    help_asset_signature_types:
        'Die für diesen Vermögenswert verfügbaren Zeichnungsoptionen.',
    help_asset_signature_legal_notice: 'Hinweis zur Zeichnung.',
    help_asset_document_type: 'Die Art des Dokuments.',
    help_asset_document_description: 'Beschreibung des Dokuments',
    help_asset_documents_table_0:
        'Diese Dokumente werden dem Investor via Mail geschickt.',
    help_asset_documents_table_1:
        'Die Reporting Dokumente werden im Frontend angezeigt.',
    help_asset_documents_table_2:
        'Diese Dokumente werden dem Investor vor Abschluss des Investments via Mail zugeschickt.',
    help_transfer_to_project_wallet_wallet:
        'Die Wallet, welche den Betrag erhält.',
    help_transfer_to_project_wallet_date:
        'Investments bis zu diesem Datum werden übertragen.',
    help_campaign_title: 'Der Titel der Kampagne',
    help_campaign_subtitle: 'Der Untertitel der Kampagne',
    help_country_campaign:
        'Wählen Sie das Land, in welchem die Kampagne veröffentlicht werden soll.',
    help_language_campaign: 'Wählen Sie die Sprache der Kampagne.',
    help_campaign_summary: 'Die Zusammenfassung der Kampagne.',
    help_platform_name_campaign:
        'Wählen Sie die Plattform, welche die Kampagne veröffentlicht.',
    help_asset_title_campaign:
        'Wählen Sie den Vermögenswert, welcher mit der Kampagne verknüpft werden soll.',
    help_campaign_excerpt: 'Der Auszug der Kampagne',
    help_funding_start: 'Start der Finanzierung der Kampagne',
    help_funding_end: 'Ende der Finanzierung der Kampagne',
    help_regulatory_requirements:
        'Die regulatorischen Anforderungen für diese Kampagne.',
    help_agio_disagio: 'Agio/Disagio',
    help_agio_disagio_title: 'Der Agio/Disagio Titel.',
    help_agio_disagio_rate: 'Die Agio/Disagio Rate.',
    help_agio_disagio_type: 'Der Agio/Disagio Typ.',
    help_agio_disagio_info_text: 'Der Agio/Disagio Infotext.',
    help_agio_disagio_disclaimer: 'Die Agio/Disagio Warnung.',
    help_agio_disagio_voucher:
        'Aktivieren oder deaktivieren sie Gutscheine für Agio/Disagio.',
    help_voucher_code: 'Der Gutscheincode für den Agio/Disagio Gutschein.',
    help_voucher_rate: 'Der Rabatt, welcher durch den Gutschein gewährt wird.',
    help_status_campaign: 'Der Status der Kampagne.',
    help_funding_stats_visible:
        'Soll der Finanzierungsstatus öffentlich angezeigt werden.',
    help_password_campaign:
        'Das Passwort, welches zur Sicherung der Kampagne benötigt wird. Nur Benutzer mit Zugang können investieren.',
    help_broker_status: 'Soll die Broker Warnung angezeigt werden?',
    help_broker_field_required:
        'Soll das Broker Feld als Pflichtfeld angezeigt werden?',
    help_broker_disclaimer: 'Die Broker Warnung, welche angezeigt wird.',
    help_external_project_url: 'Link zu einer externen Projektseite.',
    help_campaign_badge:
        'Wählen Sie das Badge, welches auf der Kampagnenseite angezeigt werden soll.',
    help_show_amount_slider:
        'Soll der Betragsregler während des Checkouts angezeigt werden.',
    help_campaign_thumb_image:
        'Das Thumbnail der Kampagne, welches im Frontend angezeigt wird.',
    help_campaign_hero_image:
        'Das Hauptbild der Kampagne, welches im Frontend angezeigt wird.',
    help_main_campaign: 'Der Hauptinhalt der Kampagne.',
    help_summary_campaign: 'Zusammenfassung der Kampagne.',
    help_associate_orders_modal_text:
        'Möchten Sie dem Vertrag {contract} alle Bestellungen, die noch keinem Vertrag zugeordnet sind zuweisen?',
    help_drag_and_drop:
        'Ziehen Sie die Elemente, um die Reihenfolge zu ändern.',
    help_campaign_content_block_title: 'Titel des Bausteins.',
    help_campaign_content_block_content: 'Inhalt des Bausteins.',
    help_campaign_incentive_amount:
        'Der Anreiz Betrag, welcher dem Investor gewährt wird.',
    help_campaign_incentive_description: 'Die Beschreibung des Anreiz.',
    help_campaign_incentive_title: 'Der Titel des Anreizes.',
    help_campaign_team_member_name: 'Name des Teammitglieds.',
    help_campaign_team_member_title: 'Titel des Teammitglieds.',
    help_campaign_team_member_bio: 'Biografie des Teammitglieds.',
    help_campaign_team_member_image: 'Bild des Teammitglieds.',
    help_order_invested_amount: 'Der investierte Betrag.',
    help_order_send_mails: 'Investoren Emails schicken.',
    help_date_of_order: 'Das Datum der Investition.',
    help_select_campaign: 'Wählen Sie die Kampagne.',
    help_select_incentive: 'Wählen Sie einen Anreiz.',
    help_contract_type: 'Wählen Sie die Vertragsart.',
    help_search_account_title: 'Suchen Sie nach einem Investor.',
    help_search_field: 'Geben Sie mindestens 3 Zeichen ein.',
    help_email: 'Die E-Mail Adresse des Investors.',
    help_city: 'Der Wohnort des Investors.',
    help_date: 'Datum der Bestellung.',
    help_first_name: 'Der Vorname des Investors.',
    help_last_name: 'Der Nachname des Investors.',
    help_place_of_birth: 'Der Geburtsort des Investors.',
    help_date_of_birth: 'Das Geburtsdatum des Investors.',
    help_id_card_passport_number:
        'Die Personalausweisnummer oder Reisepass des Investors.',
    help_issuing_authority:
        'Die Ausstellungsbehörde des Personalausweis oder Reisepass.',
    help_address: 'Die Adresse des Investors.',
    help_postal_code: 'Die Postleitzahl.',
    help_state: 'Die Region des Investors.',
    help_tax_number: 'Die Steuernummer des Investors.',
    help_order_attachment_type: 'Geben Sie den Typ des Anhangs an.',
    help_order_attachment_name: 'Der Name des Anhangs.',
    help_order_attachment_description: 'Die Beschreibung des Anhangs.',
    help_additional_fields_enabled:
        'Aktivieren oder deaktivieren Sie die zusätzlichen Felder (Es werden immer alle Felder aktiviert oder deaktiviert). Die folgenden Schalter sind für die verpflichtende Eingabe der jeweiligen Felder.',
    help_depot_owner: 'Der Depotinhaber.',
    help_depot_number: 'Die Depotnummer.',
    help_depot_bank: 'Die Depotbank.',
    help_depot_bic: 'BIC der Depotbank.',
    help_bulk_change_order_status: 'Wählen Sie den neuen Status.',
    help_contract_duration: 'Geben Sie die Vertragsdauer an.',
    help_first_payout: 'Das erste Auszahlungsdatum.',
    help_last_payout: 'Das letzte Auszahlungsdatum.',
    help_total_payments_count:
        'Die Anzahl der Auszahlungen für diesen Vertrag.',
    help_start_of_contract: 'Das Startdatum des Vertrags.',
    help_early_bird_promotion:
        'Aktivieren oder deaktivieren Sie die Early Bird Promotion.',
    help_early_bird_interest_rate: 'Der Zinssatz für die Early Bird Promotion.',
    help_early_bird_deadline:
        'Das Datum bis zu dem die Early Bird Promotion gilt.',
    help_calculation_method:
        'Wählen Sie die Berechnungsmethode für den Zinssatz.',
    help_calculation_start_date:
        'Das Datum ab dem der Zinssatz berechnet wird.',
    help_bullet_loan:
        'Aktivieren oder deaktivieren Sie Auszahlungen am Ende der Vertragsdauer.',
    help_deferred_clearance:
        'Die Anzahl der Auszahlungen um welche die Tilgung verschoben wird.',
    help_external_wallet_id:
        'Die externe Wallet ID für die Ein- und Auszahlungen.',
    help_voucher_wallet:
        'Von diesem Lemonway Wallet werden die eingelösten Vouchers abgebucht.',
    help_voucher_campaign_name: 'Name der Voucher Kampagne.',
    help_voucher_campaign_type: 'Wählen Sie den Typ der Voucher Kampagne.',
    help_voucher_campaign_default: 'Wählen Sie die Standard Voucher Kampagne.',
    help_pay_percent_of_rest: 'Der Prozentsatz der Restsumme.',
    help_interest_bonus: 'Der Zinsbonus.',
    help_open_file_in_new_tab: 'File in neuem Tab öffnen',
    help_show_verification_comment: 'Kommentar anzeigen',
    help_account_first_name: 'Vorname',
    help_account_last_name: 'Nachname',
    help_account_email: 'E-Mail Adresse',
    help_account_nationality: 'Nationalität',
    help_account_gender: 'Geschlecht',
    help_account_country: 'Land',
    help_account_email_pending: 'E-Mail Bestätigung',
    help_account_company_description: 'Unternehmensgegenstand',
    help_account_company_registration_number: 'Unternehmenregistrationsnummer',
    help_account_company_name: 'Firmenname',
    help_account_name: 'Name des Investors',
    help_account_custom_id: 'Individuelle ID',
    help_account_address: 'Adresse',
    help_account_zip: 'PLZ',
    help_account_city: 'Stadt',
    help_account_state: 'Bundesland',
    help_account_phone: 'Telefonnummer',
    help_account_mobile: 'Mobiltelefonnummer',
    help_account_iban: 'IBAN',
    help_account_bic: 'BIC',
    help_account_tax_number: 'Steuernummer',
    help_account_social_security_number: 'Sozialversicherungsnummer',
    help_campaigns_table:
        'Die Liste der Kampagnen auf die Sie zugreifen können.',
    help_contracts_table:
        'Die Liste der Verträge auf die Sie zugreifen können.',
    help_bank_account: 'Verifiziertes Bankkonto',
    help_money_withdrawal_comment: 'Kommentar zur Abhebung',
    help_money_withdrawal_amount: 'Summe der Abhebung',
    help_kyc_document_type: 'Dokumenttyp',
    help_kyc_document_iban: 'IBAN',
    help_kyc_document_account_holder: 'Bankkontoinhaber',
    help_edit_order: 'Bestellung bearbeiten',
    help_campaign_content_images_table: 'Die Kampagnen Bilder.',
    help_campaign_content_blocks_table: 'Die Content Bausteine der Kampagne.',
    help_platform_attachments_table: 'Die Plattform Anhänge.',
    help_platform_webhooks_table: 'Die Plattform Webhooks.',
    help_platform_policies_table: 'Die Platform Richtlinien.',
    help_platform_email_templates_table: 'Die Platform Email Templates.',
    help_platform_disclaimers_table: 'Die Platform Warnungen.',
    help_bulk_change_order_campaign: 'Wählen Sie die Kampagne.',
    help_assets_table: 'Die Vermögenswerte.',
    help_asset_contracts_table: 'Die Verträge des Vermögenswert.',
    help_platforms_table: 'Die Plattformen.',
    help_orders_table: 'Die Bestellungen.',
    help_investor_modal: 'Der Investor in dessen Namen sie gerade agieren.',
    help_valid_to: 'Gültig-bis Datum muss mindestens übermorgen sein: ',
    help_verification_status: 'Verifikationsstatus',
    help_comment: 'Kommentar',
    help_incentives_table: 'Die Anreize.',
    help_bank_accounts_table: 'The bank accounts.',
    help_view_order: 'View order',
    help_wallet_transaction_table: 'The wallet transactions table',
    help_withdrawals_table: 'The withdrawals table',
    help_accounts_table: 'Investoren',
    help_natural_profiles: 'Verknüpfte natürliche Personen',
    help_view_natural_profile: 'Profil der natürlichen Person anzeigen',
    help_order_attachments_table: 'Anhänge',
    help_manual_investments:
        'Mit Aktivierung der Checkbox wird die Anzeige für die Anzahl wie die Gesamtsumme von Investments manuell geändert.',
    help_team_members_table: 'Liste der Team Mitglieder.',
    help_order_status:
        'Wählen Sie das Status Datum, welches sie anpassen möchten.',
    help_order_status_date:
        'Geben Sie das Datum an, auf welches das Status Datum gesetzt werden soll.',
    help_transactions_table: 'Transaktionen',
    help_emails_table: 'E-Mails',
    help_default_language: 'Standardsprache',
    help_savings_plan: 'Aktivierung des Ansparplan E-Mail',
    help_savings_plan_description:
        'Dieser Text wird angezeigt in der Zusammenfassung des Investmentprozesses',

    modal_title_export_result: 'Export',
    modal_title_delete_platform: 'Plattform löschen?',
    modal_title_delete_platforms: 'Plattformen löschen?',
    modal_title_update_platform_attachment: 'Plattform Anhang bearbeiten',
    modal_title_create_platform_attachment: 'Plattform Anhang erstellen',
    modal_title_delete_platform_attachment: 'Plattform Anhang löschen?',
    modal_title_delete_platform_attachments: 'Plattform Anhänge löschen?',
    modal_title_update_platform_webhook: 'Plattform Webhook bearbeiten',
    modal_title_create_platform_webhook: 'Plattform Webhook erstellen',
    modal_title_delete_platform_webhook: 'Plattform Webhook löschen?',
    modal_title_delete_platform_webhooks: 'Plattform Webhooks löschen?',
    modal_title_update_platform_policy: 'Plattform Richtlinie bearbeiten',
    modal_title_create_platform_policy: 'Plattform Richtlinie erstellen',
    modal_title_delete_platform_policy: 'Plattform Richtlinie löschen?',
    modal_title_delete_platform_policies: 'Plattform Richtlinien löschen?',
    modal_title_update_platform_email_template:
        'Plattform E-Mail Vorlage bearbeiten',
    modal_title_create_platform_email_template:
        'Plattform E-Mail Vorlage erstellen',
    modal_title_delete_platform_email_template:
        'Plattform E-Mail Vorlage löschen?',
    modal_title_delete_platform_email_templates:
        'Plattform E-Mail Vorlagen löschen?',
    modal_title_update_platform_disclaimer: 'Plattform Warnung bearbeiten',
    modal_title_create_platform_disclaimer: 'Plattform Warnung erstellen',
    modal_title_delete_platform_disclaimer: 'Plattform Warnung löschen?',
    modal_title_delete_platform_disclaimers: 'Plattform Warnungen löschen?',
    modal_title_delete_asset: 'Vermögenswert löschen?',
    modal_title_delete_assets: 'Vermögenswerte löschen?',
    modal_title_update_asset_document: 'Vertragsdokument bearbeiten',
    modal_title_create_asset_document: 'Vertragsdokument erstellen',
    modal_title_update_ecsp_document: 'ECSP Dokument bearbeiten',
    modal_title_view_ecsp_document: 'ECSP Dokument ansehen',
    modal_title_update_asset_document_0:
        'Vertragsdokument bearbeiten (Vertragsdaten)',
    modal_title_create_asset_document_0:
        'Vertragsdokument erstellen (Vertragsdaten)',
    modal_title_update_asset_document_1:
        'Vertragsdokument bearbeiten (Reporting)',
    modal_title_create_asset_document_1:
        'Vertragsdokument erstellen (Reporting)',
    modal_title_update_asset_document_2:
        'Vertragsdokument bearbeiten (Verbraucherinformation)',
    modal_title_create_asset_document_2:
        'Vertragsdokument erstellen (Verbraucherinformation)',
    modal_title_delete_asset_document: 'Vertragsdokument löschen?',
    modal_title_delete_asset_documents: 'Vertragsdokumente löschen?',
    modal_title_transfer_to_project_wallet: 'Auf Projekt Wallet überweisen',
    modal_title_delete_campaign: 'Kampagne löschen?',
    modal_title_delete_campaigns: 'Kampagnen löschen?',
    modal_title_associate_orders: 'Bestellungen verknüpfen?',
    modal_title_delete_asset_contract: 'Vertrag löschen?',
    modal_title_delete_asset_contracts: 'Verträge löschen?',
    modal_title_delete_campaign_content_image: 'Bild löschen?',
    modal_title_delete_campaign_content_images: 'Bilder löschen?',
    modal_title_delete_campaign_content_block: 'Baustein löschen?',
    modal_title_delete_campaign_content_blocks: 'Bausteine löschen?',
    modal_title_view_campaign_content_block: 'Baustein ansehen',
    modal_title_edit_campaign_content_block: 'Baustein bearbeiten',
    modal_title_create_campaign_content_block: 'Baustein erstellen',
    modal_title_view_campaign_team_member: 'Teammitglied ansehen',
    modal_title_create_campaign_team_member: 'Teammitglied erstellen',
    modal_title_edit_campaign_team_member: 'Teammitglied bearbeiten',
    modal_title_delete_campaign_team_member: 'Teammitglied löschen?',
    modal_title_delete_campaign_team_members: 'Teammitglieder löschen?',
    modal_title_view_campaign_incentive: 'Anreiz ansehen',
    modal_title_edit_campaign_incentive: 'Anreiz bearbeiten',
    modal_title_create_campaign_incentive: 'Anreiz erstellen',
    modal_title_delete_campaign_incentive: 'Anreiz löschen?',
    modal_title_delete_campaign_incentives: 'Anreiz löschen?',
    modal_title_create_order: 'Bestellung erstellen',
    modal_title_edit_order_attachment: 'Anhang bearbeiten',
    modal_title_create_order_attachment: 'Anhang erstellen',
    modal_title_delete_order_attachment: 'Anhang löschen?',
    modal_title_delete_order_attachments: 'Anhänge löschen?',
    modal_title_confirm_signature: 'Signatur bestätigen',
    modal_title_cancel_order: 'Bestellung Abbrechen?',
    modal_title_accept_order: 'Bestellung akzeptieren?',
    modal_title_set_order_status_to_pending:
        'Bestellstatus auf "Ausstehend" setzen?',
    modal_title_set_order_status_to_paid: 'Bestellstatus auf "Bezahlt" setzen?',
    modal_title_delete_contract: 'Vertrag löschen?',
    modal_title_delete_contracts: 'Verträge löschen?',
    modal_title_delete_task: 'Aufgabe löschen?',
    modal_title_confirm_update_contract: 'Vertragsänderung bestätigen',
    modal_title_new_interest_payment_calculation: 'Neue Zinsberechnung',
    modal_title_execute_interest_payment: 'Zinszahlung ausführen',
    modal_title_payout_task: 'Auszahlung',
    modal_title_start_interest_payment_payout: 'Zinszahlung ausführen',
    modal_title_create_money_withdrawal: 'Abhebung von Wallet hinzufügen',
    modal_title_show_kyc_document_comment: 'Kommentare zum Dokument',
    modal_title_create_bank_kyc_document: 'Upload - Bankkonto KYC Dokument',
    modal_title_create_address_kyc_document: 'Upload - KYC Dokument - Adresse',
    modal_title_create_tax_kyc_document: 'Upload - KYC Dokument - Steuerdaten',
    modal_title_bulk_change_order_status: 'Bestellstatus ändern',
    modal_title_bulk_change_order_campaign: 'Kampagne ändern',
    modal_title_delete_natural_profile: 'DSGVO Daten löschen?',
    modal_title_delete_legal_profile: 'Account löschen?',
    modal_title_update_kyc_document: 'KYC Dokument bearbeiten',
    modal_title_create_id_kyc_document: 'Upload - KYC Dokument - Identität',
    modal_title_create_legal_kyc_document:
        'Upload - KYC Dokument - Prüfung der Unternehmensdaten',
    modal_title_create_nautral_account: 'Natürliche Person erstellen',
    modal_title_change_campaign_status: 'Kampagnenstatus ändern',
    modal_title_change_order_status_date: 'Status Datum ändern',

    modal_text_confirm_signature:
        'Sind Sie sicher, dass sie die Signatur manuell bestätigen wollen? Dies sollte nur nach Erhalt und Überprüfung eines rechtlich bindenden Dokuments erfolgen.',
    modal_text_regenerate_signature:
        'Sind Sie sicher, dass Sie die Signaturen neu generieren möchten? Alle derzeit aktiven Signaturen werden zurückgesetzt.',
    modal_text_order_status_set_order_status_paid:
        'Ja, setze den Bestellstatus auf "Bezahlt".',
    modal_text_order_status_accept:
        'Ja, setze den bestellstatus auf "Akzeptiert".',
    modal_text_order_status_set_order_status_pending:
        'Ja, setze den Bestellstatus auf "Ausstehend".',
    modal_text_order_status_set_order_status_canceled:
        'Ja, setze den Bestellstatus auf "Abgebrochen".',
    modal_text_confirm_update_contract:
        'The contract with id {contract} has orders. Do you want to updated this contract?',
    modal_text_execute_interest_payment:
        'Wollen Sie diese Aktion wirklich ausführen? Diese Aktion kann nicht rückgängig gemacht werden.',
    modal_text_start_interest_payment_payout:
        'Sind Sie sich sicher, dass die Zinsauszahlungan auf die Wallets ausgeführt werden sollen?',
    modal_text_delete_natural_profile:
        'Sind Sie sich sicher, dass Sie alle Daten dieses Accounts löschen wollen? Dies ist eine DSGVO-Lösung (keine normale Löschung/Deaktivierung). Bei dieser Löschung werden alle Daten wie KYC-Dokumente, Audits, etc. mitgelöscht. Alte Versionen des Accounts werden ebenfalls gelöscht. Als alte Accounts zählen deaktivierte Accounts mit der selber E-Mailadresse in der selben Plattform.',
    modal_text_delete_legal_profile:
        'Bitte bestätigen Sie das Löschen des Accounts.',
    modal_text_delete_task:
        'Sind Sie sich sicher, dass Sie die Aufgabe "{type}", die am {created} erstellt wurde löschen möchten?',

    toast_error_cannot_delete_platform: 'Plattform kann nicht entfernt werden!',
    toast_error_access_denied:
        'Sie sind nicht berechtigt diese Seite zu sehen: {path}.',
    toast_success_platform_deleted: 'Plattform "{platform}" wurde entfernt.',
    toast_success_platforms_deleted:
        'Plattformen "{platforms}" wurden entfernt.',
    toast_success_applying_filters: 'Wende filter an: {filters}',
    toast_success_clear_filters: 'Filter werden zurückgesetzt ...',
    toast_success_platform_created: 'Plattform wurde erstellt!',
    toast_success_platform_saved: 'Plattform "{platform}" gespeichert!',
    toast_success_platform_attachment_created:
        'Plattform Anhang "{attachment}" erstellt!',
    toast_success_platform_attachment_updated:
        'Plattform Anhang "{attachment}" aktualisiert!',
    toast_success_platform_attachment_deleted:
        'Plattform Anhang "{attachment}" entfernt!',
    toast_success_platform_attachments_deleted:
        'Plattform Anhänge "{attachments}" entfernt!',
    toast_success_platform_webhook_created:
        'Plattform Webhook "{action}" erstellt!',
    toast_success_platform_webhook_updated:
        'Plattform Webhook "{action}" aktualisiert!',
    toast_success_platform_webhook_deleted:
        'Plattform Webhook "{action}" entfernt!',
    toast_success_platform_webhooks_deleted:
        'Plattform Webhook "{actions}" entfernt!',
    toast_success_platform_policy_created:
        'Plattform Richtlinie "{policy}" erstellt!',
    toast_success_platform_policy_updated:
        'Plattform Richtlinie "{policy}" aktualisiert!',
    toast_success_platform_policy_deleted:
        'Plattform Richtlinie "{policy}" entfernt!',
    toast_success_platform_policies_deleted:
        'Plattform Richtlinien "{policies}" entfernt!',
    toast_success_platform_email_template_updated:
        'Plattform E-Mail Vorlage "{template}" bearbeitet!',
    toast_success_platform_email_template_created:
        'Plattform E-Mail Vorlage "{template}" erstellt!',
    toast_success_platform_email_template_deleted:
        'Plattform E-Mail Vorlage "{template}" entfernt!',
    toast_success_platform_email_templates_deleted:
        'Plattform E-Mail Vorlagen "{templates}" entfernt!',
    toast_success_platform_email_template_sent:
        'Plattform E-Mail Vorlage "{template}" gesendet!',
    toast_success_platform_disclaimer_updated:
        'Plattform Warnung "{disclaimer}" bearbeitet!',
    toast_success_platform_disclaimer_created:
        'Plattform Warnung "{disclaimer}" erstellt!',
    toast_success_platform_disclaimer_deleted:
        'Plattform Warnung "{disclaimer}" entfernt!',
    toast_success_platform_disclaimers_deleted:
        'Plattform Warnungen "{disclaimers}" entfernt!',
    toast_success_asset_created: 'Vermögenswert wurde erstellt!',
    toast_error_cannot_delete_asset:
        'Vermögenswert kann nicht entfernt werden!',
    toast_success_asset_deleted: 'Vermögenswert wurde erfolgreich entfernt!',
    toast_success_assets_deleted: 'Vermögenswerte wurden erfolgreich gelöscht.',
    toast_success_campaign_created: 'Kampagne erstellt!',
    toast_success_campaign_duplicated: 'Kampagne dupliziert!',
    toast_success_asset_saved: 'Vermögenswert wurde erfolgreich gespeichert!',
    toast_success_campaign_saved: 'Kampagne wurde erfolgreich gespeichert!',
    toast_success_asset_document_updated:
        'Vertragsdokument "{document}" aktualisiert!',
    toast_success_asset_document_created:
        'Vertragsdokument "{document}" erstellt!',
    toast_success_asset_document_deleted:
        'Vertragsdokument "{document}" gelöscht!',
    toast_success_asset_documents_deleted:
        'VertragsdokumentE "{documents}" gelöscht!',
    toast_success_ad_hoc_email_sent:
        'Ad-hoc E-Mail wurde erfolgreich gesendet!',
    toast_success_transfer_to_project_wallet:
        'Starte Überweisung zur Projekt Wallet: {log}',
    toast_success_associate_orders:
        '{orders} Bestellungen wurden mit dem Vertrag {contract} verknüpft.',
    toast_success_campaign_content_image_deleted:
        'Beitragsbild {contentImage} gelöscht!',
    toast_success_campaign_content_images_deleted:
        'Beitragsbilder {contentImages} gelöscht!',
    toast_success_campaign_content_image_created:
        'Beitragsbild {contentImage} erstellt!',
    toast_success_campaign_content_block_updated:
        'Baustein {contentBlock} aktualisiert!',
    toast_success_campaign_content_block_created:
        'Baustein {contentBlock} erstellt!',
    toast_success_campaign_content_block_deleted:
        'Baustein {contentBlock} gelöscht!',
    toast_success_campaign_content_blocks_deleted:
        'Bausteine {contentBlocks} gelöscht!',
    toast_success_campaign_team_member_created:
        'Teammitglied {teamMember} erstellt!',
    toast_success_campaign_team_member_updated:
        'Teammitglied {teamMember} aktualisiert!',
    toast_success_campaign_team_member_deleted:
        'Teammitglied {teamMember} gelöscht!',
    toast_success_campaign_team_members_deleted:
        'Teammitglieder {teamMembers} gelöscht!',
    toast_success_order_attachment_deleted: 'Anhang {attachment} gelöscht!',
    toast_success_order_attachments_deleted: 'Anhänge {attachments} gelöscht!',
    toast_success_order_attachment_updated: 'Anhang {attachment} aktualisiert',
    toast_success_order_attachment_created: 'Anhang {attachment} erstellt',
    toast_success_order_status_changed:
        'Bestellstatus von Bestellung {order} geändert!',
    toast_success_order_updated: 'Bestellung {order} aktualisiert!',
    toast_success_order_created: 'Bestellung erstellt!',
    toast_success_orders_status_changed:
        'Status der Bestellungen {orders} erfolgreich geändert!',
    toast_success_campaign_deleted: 'Kampagne erfolgreich entfernt!',
    toast_success_campaigns_deleted: 'Kampagnen erfolgreich entfernt!',
    toast_success_contract_saved: 'Vertrag {contract} erfolgreich geändert!',
    toast_success_contract_deleted: 'Vertrag {contract} erfolgreich entfernt!',
    toast_success_contracts_deleted:
        'Verträge {contracts} erfolgreich entfernt!',
    toast_success_interest_payment_execute: 'Zinszahlung wurde erstellt!',
    toast_success_interest_payment_payout_started: 'Zinszahlung gestartet!',
    toast_success_orders_campaign_changed:
        'Kampagne der Bestellungen {orders} geändert!',

    toast_success_withdrawal_created: 'Abhebung erfolgreich hinzugefügt!',
    toast_success_campaign_status_changed:
        'Kampagnen Status erfolgreich geändert!',

    toast_error_withdrawal_creation_failed:
        'Abhebung konnte nicht erstellt werden!',

    toast_success_natural_profile_updated: 'Speichern der Daten erfolgreich!',
    toast_error_natural_profile_update_failed:
        'Speichern der Daten fehlgeschlagen!',

    toast_success_kyc_document_created:
        'Dokument wurde erfolgreich hochgeladen!',
    toast_error_kyc_document_create:
        'Dokument konnte nicht hochgeladen werden!',

    toast_success_legal_profile_updated: 'Speichern der Daten erfolgreich!',
    toast_error_legal_profile_update_failed:
        'Speichern der Daten fehlgeschlagen!',
    toast_error_wallet_sync_failed: 'Wallet Synchronisation fehlgeschlagen!',
    toast_success_wallet_synced: 'Wallet Synchronisation erfolgreich!',
    toast_success_natural_profile_deleted: 'DSGVO deleted!',
    toast_success_legal_profile_deleted: 'Account deleted!',
    toast_success_legal_account_created: 'Geschäftskonto erfolgreich angelegt!',
    toast_success_account_created:
        'Account für natürliche Person erfolgreich erstellt',
    toast_success_kyc_document_updated: 'KYC document erfolgreich gepeichert!',
    toast_error_kyc_document_updated:
        'Speichern des KYC Dokuments fehlgeschlagen!',
    toast_success_campaign_incentive_created: 'Incentive erfolgreich erstellt!',
    toast_success_campaign_incentive_updated:
        'Incentive erfolgreich aktualisiert!',

    warning_failed_transactions_count:
        'Warnung: Fehlgeschlagene Transaktionen: {count}.',
    warning_missing_iban:
        'Warnung: Bei {count} Bestellung(en) wurde kein IBAN angegeben.',
    warning_missing_bic:
        'Hinweis: Bei {count} Bestellung(en) wurde kein BIC angegeben.',
    warning_order_failed_transactions_count:
        'Anzahl fehlgeschlagener Transaktionen: {count}',
    warning_order_missing_iban: 'Kein IBAN',
    warning_order_missing_bic: 'Kein BIC',

    // Enums //

    // CampaignStatus //
    DRAFT: 'Entwurf',
    ANNOUNCEMENT: 'Ankündigung',
    PRE_FUNDING: 'Pre-Funding',
    FUNDING: 'Finanzierung',
    FUNDING_SUCCESSFUL: 'Erfolgreich',
    FUNDING_UNSUCCESSFUL: 'Nicht erfolgreich',
    PRIVATE_PLACEMENT: 'Private Platzierung',

    // RegulatoryRequirements //
    DEFAULT: 'Default',
    ALTFG: 'AltFG',
    VERMANLG: 'VermAnlG',
    WPHG: 'WpHG-Aktien',
    ECSP: 'ECSP',

    // AgioDisagio //
    DEACTIVATED: 'Deaktiviert',
    AGIO_ACTIVATED: 'Agio',
    DISAGIO_ACTIVATED: 'Disagio',

    // Contract type
    CASH: 'Geld',
    VOUCHER: 'Gutschein',

    // Campaign Badge
    NEW: 'Neu',
    WE_LOVE_THIS: 'We love this',
    FAVORITE: 'Favorite',
    STARTING_SOON: 'Startet bald',
    IMPACT_INVESTMENT: 'Impact Investment',

    // Order Attachment Type
    INVESTMENT_DOCUMENT: 'Investitionsdokument',
    OTHER: 'Anderes',

    // SignatureDocumentType
    EMISSION: 'Emission',
    ESAC_FORM_A: 'Anlegerprofil (FORM A)',
    ESAC_FORM_B: 'Erklärung des wirtschaftlich Berechtigten (FORM B)',
    ESAC_FORM_C: 'FATCA-Erklärung (Form C)',
    ESAC_FORM_D: 'FATCA-Erklärung (Form D)',

    // SignatureType
    MANUAL: 'Manual',
    SPROOF: 'Sproof',

    // OrderStatus
    CANCELED: 'Abgebrochen',
    PENDING: 'Ausstehend',
    PAID: 'Bezahlt',
    ACCEPTED: 'Akzeptiert',

    // ContractPeriodType
    FIXED: 'Fixed',
    CONTINUOUS: 'Continuous',

    // ContractPeriodStart
    CURRENT: 'current',
    NEXT: 'next',

    // InterestRateCalculationMethod
    IR_ACT_360: 'ACT/360',
    IR_ACT_365: 'ACT/365',
    IR_30_360: '30/360',

    // CalculationStartDate
    ACCEPTANCE_DATE: 'Akzeptiert am',
    PAID_DATE: 'Bezahlt am',

    // PayoutTaskFinishedType
    SUCCESS: 'Erfolg',
    WARNING: 'Warnung',
    HAS_ERRORS: 'Hat Fehler',
    ERROR: 'Fehler',
    ERROR_WHILE_GENERATING: 'Fehler beim Generieren',

    // PrimaryUserRoles
    DEVELOPER: 'Developer',
    SUPERADMIN: 'Superadmin',
    PLATFORM_ADMIN: 'Platform Admin',
    FRONTOFFICE_MANAGER: 'Frontoffice Manager',
    BACKOFFICE_MANAGER_CONDA: 'Backoffice Manager Conda',
    BACKOFFICE_MANAGER_WL: 'Backoffice Manager WL',
    READ_ONLY: 'Read Only',
    // Accounts
    MALE: 'männlich',
    FEMALE: 'weiblich',
    NON_BINARY: 'nicht binär',

    // ECSP Status
    EXPERT: 'Kundig',
    NON_EXPERT: 'Nicht Kundig (Abgelehnt)',
    EXPERT_PENDING: 'Nicht Kundig (zu prüfen)',
    NOTHING_TO_CHECK: 'Nicht zu Prüfen',

    // KYC Dokumenttyp
    PASSPORT: 'Reisepass',
    INVOICE: 'Rechnung',
    ID_CARD: 'Personalausweis',
    RESIDENCE_CONFIRMATION: 'Wohnsitzbestätigung',
    BANK_STATEMENT: 'Kontoauszug',
    SDD_MANDATE: 'SEPA-Lastschriftmandat',
    COMPANY_REGISTRATION: 'Firmenregistrierung',
    ARTICLES_OF_ASSOCIATION: 'Gesellschaftsvertrag',
    KYC_OTHER: 'andere',

    // KYC Document Status
    KYC_DOC_STATUS_IN_PROGRESS: 'in Bearbeitung',
    KYC_DOC_STATUS_ON_HOLD: 'on hold',
    KYC_DOC_STATUS_ACCEPTED: 'akzeptiert',
    KYC_DOC_STATUS_NOT_ACCEPTED: 'abgelehnt',
    KYC_DOC_STATUS_UNREADABLE: 'unleserlich',
    KYC_DOC_STATUS_EXPIRED: 'abgelaufen',
    KYC_DOC_STATUS_WRONG_TYPE: 'falscher Typ',
    KYC_DOC_STATUS_WRONG_NAME: 'falscher Name',
    KYC_DOC_STATUS_DUPLICATE_DOCUMENT: 'doppeltes Dokument',

    // Bank Account Status
    VERIFICATION_PENDING: 'in Bearbeitung',
    VERIFIED: 'verifiziert',
    REJECTED: 'abgelehnt',

    // MONEY WITHDRAWAL STATUS
    WITHDRAWAL_PENDING: 'Ausstehend',
    WITHDRAWAL_DONE: 'Abgeschlossen',
    WITHDRAWAL_REJECTED: 'Abgelehnt',

    WALLET_STATUS_SC: 'SC Wallet',
    WALLET_STATUS_NOT_OPENED: 'Nicht geöffnet',
    WALLET_STATUS_INCOMPLETE: 'Unvollständig',
    WALLET_STATUS_REJECTED: 'Abgelehnt',
    WALLET_STATUS_KYC1: 'KYC 1',
    WALLET_STATUS_KYC2: 'KYC 2',
    WALLET_STATUS_KYC3: 'KYC 3',
    WALLET_STATUS_EXPIRED: 'Abgelaufen',
    WALLET_STATUS_BLOCKED: 'Geblocked',
    WALLET_STATUS_CLOSED: 'Geschlossen',
    WALLET_STATUS_KYC_UPDATING_2_3: 'Updating von KYC 2 zu 3',
    WALLET_STATUS_ONE_TIME_CUSTOMER: 'Einmaliger Kunde',
    WALLET_STATUS_CROWDLENDING: 'Crowdlending Wallet',
    WALLET_STATUS_TECHNIQUE: 'Technique Wallet',
    WALLET_STATUS_UNDEFINED: 'Konto hat keine Wallet',

    // ACCOUNT TYPE
    LEGAL: 'legal',
    NATURAL: 'natural',

    // EXTERNAL KYC STATUS
    EXTERNAL_KYC_CREATED: 'Erstellt',
    EXTERNAL_KYC_PENDING: 'Ausstehend',
    EXTERNAL_KYC_IN_REVIEW: 'In Review',
    EXTERNAL_KYC_SUCCESS: 'Erfolgreich',
    EXTERNAL_KYC_DONE: 'Erledigt',
    EXTERNAL_KYC_FAILED_CANCELED: 'Fehlgeschlagen - Abgebrochen',
    EXTERNAL_KYC_FAILED_FRAUDULENT: 'Fehlgeschlagen - Betrugsverdacht',

    // ZAHLUNGSMETHODEN
    BANK_CARD: 'Bankkarte',
    INCOMING_WIRE_TRANSFER: 'Eingehende Überweisung',
    OUTGOING_WIRE_TRANSFER: 'Ausgehende Überweisung',
    P2P: 'P2P',
    IDEAL: 'iDEAL',
    SEPA_DIRECT: 'SEPA Lastschrift',
    CHEQUE: 'Scheck',
    NEOSURF: 'Neosurf',
    SOFORT: 'SOFORT',
    PFS_PHYSICAL_CARD: 'PFS physische Karte',
    MULTIBANCO: 'Multibanco',
    PAYSHOP: 'Payshop',
    MB_WAY: 'MB WAY',
    POLISH_INSTANT_TRANSFER: 'Polnische Sofortüberweisung',
    PAGARE: 'Pagare',
    MOBILE_PAY: 'Mobile Pay',
    PAY_TRAIL: 'PayTrail',
    WE_CHAT: 'WeChat (über PayTend)',
    P24: 'P24',
    MONEY_IN_BY_TPE: 'Geld per TPE einziehen',
    TRUSTLY: 'Trustly',

    // OPEN BASKET STEPS
    STEP_1_INSTALMENT: 'Schritt 1 (CF - Raten)',
    STEP_1: 'Schritt 1',
    STEP_2: 'Schritt 2',
    STEP_3: 'Schritt 3',
    STEP_4: 'Schritt 4',

    // TRANSAKTIONSTYPEN
    MONEY_IN: 'Geldeingang',
    MONEY_OUT: 'Geldausgang',

    // INTERNE TRANSAKTIONSSTATUS
    SUCCESS_MONEY_IN_OUT_OR_PENDING_P2P: 'Erfolgreich',
    SUCCESS_P2P_OR_MONEY_IN_OUT_ERROR: 'Erfolgreich',
    TRANSACTION_ERROR: 'Fehlerhaft',
    CANCELLED: 'Abgebrochen',
    WAIT_FOR_VALIDATION_FINALIZATION: 'Ausstehend',
    // E-Mails
    EMAIL_TEMPLATE_WELCOME: 'Willkommen',
    EMAIL_TEMPLATE_KYC_ACCEPTED: 'KYC - Accepted',
    EMAIL_TEMPLATE_CHECK_REQUESTED_MEMBERSHIP_DOCUMENTS:
        'Angeforderte Mitgliedsdokumente',
    EMAIL_TEMPLATE_MEMBERSHIP_DOCUMENT_ACCEPTED:
        'Mitgliedsdokument wurde akzeptiert',
    EMAIL_TEMPLATE_MEMBERSHIP_DOCUMENT_REMINDER: 'Mitgliedsdokument-Erinnerung',
    EMAIL_TEMPLATE_NEWSLETTER_CONSENT: 'E-Mail-Abonnementbestätigung',
    EMAIL_TEMPLATE_CONSUMER_INFORMATION: 'Kunden Information',
    EMAIL_TEMPLATE_ACCEPTANCE_NOTICE: 'Annahmeerklärung',
    EMAIL_TEMPLATE_PAYMENT_CONFIRMATION: 'Bezahlungsbestätigung',
    EMAIL_TEMPLATE_PAYMENT_DETAILS: 'Bezahlungsdetails',
    EMAIL_TEMPLATE_PAYMENT_REMINDER: 'Bezahlungserinnerung',
    EMAIL_TEMPLATE_SAVINGS_PLAN_PAYMENT_REMINDER:
        'Ansparplan bezahlungserinnerung',
    EMAIL_TEMPLATE_KYC_REMINDER: 'KYC-Erinnerung',
    EMAIL_TEMPLATE_KYC_STATUS: 'KYC-Statusbenachrichtigung',
    EMAIL_TEMPLATE_KYC_EXPIRE_REMINDER: 'KYC Ablauf Erinnerung',
    EMAIL_TEMPLATE_BASKET_REMINDER_ONE: 'Offene Bestellung-Erinnerung 1',
    EMAIL_TEMPLATE_BASKET_REMINDER_TWO: 'Offene Bestellung-Erinnerung 2',
    EMAIL_TEMPLATE_AUTH_EMAIL_ADDRESS_CHANGED:
        'E-Mail-Änderung Benachrichtigung',
    EMAIL_TEMPLATE_AUTH_EMAIL_VERIFICATION: 'E-Mail-Änderung Verifikation',
    EMAIL_TEMPLATE_BANK_ACCOUNT_INFORMATION_REMINDER: 'IBAN-Reminder',
    EMAIL_TEMPLATE_PRE_CONSUMER_INFORMATION: 'Pre-Kundeninformation',
    EMAIL_TEMPLATE_SIGNATURE_REMINDER: 'Signatur-Erinnerung',
    EMAIL_TEMPLATE_SIGNATURES_REGENERATED: 'Signatur regeneriert',
    EMAIL_TEMPLATE_SIGNATURE_SUCCESSFUL: 'Signatur erfolgreich',
    EMAIL_TEMPLATE_EXTERNAL_KYC_STATUS_PENDING:
        'Externe KYC-Statusbenachrichtigung - Ausstehend',
    EMAIL_TEMPLATE_EXTERNAL_KYC_STATUS_SUCCESS:
        'Externe KYC-Statusbenachrichtigung - Erfolg',
    EMAIL_TEMPLATE_EXTERNAL_KYC_PROCESS_ABORTED:
        'Externer KYC-Prozess abgebrochen',
    EMAIL_TEMPLATE_MONEY_WITHDRAWAL_VERIFICATION: 'Auszahlung - Bestätigung',
    EMAIL_TEMPLATE_INTEREST_PAYMENT_ANNOUNCEMENT: 'Zinszahlung - Ankündigung',
    EMAIL_TEMPLATE_INTEREST_PAYMENT_PAYOUT_RECEIVED:
        'Zinszahlung - Auszahlung erhalten',
    EMAIL_TEMPLATE_EMPTY_OPTION: 'Keine E-Mail Vorlage',
    EMAIL_TEMPLATE_VOUCHER_RECEIVED: 'Voucher Erhalten',
    EMAIL_TEMPLATE_NO_IBAN_REMINDER: 'Ad-hoc IBAN-Reminder',
    EMAIL_TEMPLATE_FEEDBACK_REVIEW: 'Feedback / Bewertung',
    EMAIL_TEMPLATE_VOUCHER_BALANCE: 'Voucher Guthaben Benachrichtigung',
    EMAIL_TEMPLATE_WALLET_BALANCE: 'Wallet Guthaben Benachrichtigung',
    EPC: 'EPC',
    SWISS: 'Swiss',

    // Platform Policies
    TERMS_OF_SERVICE: 'Nutzungsbedingungen',
    COOKIE_POLICY: 'Cookie-Richtlinie',

    // Asset Categories
    STARTUP: 'Startup',
    SME: 'SME',
    REAL_ESTATE: 'Real Estate',
    SPECIAL: 'Special',

    // IssuerIndustry
    AGRICULTURE: 'Landwirtschaft',
    ENERGY_AND_UTILITIES: 'Energie und Versorgungsunternehmen',
    MINING: 'Bergbau',
    CONSTRUCTION: 'Baugewerbe',
    INDUSTRY: 'Industrie',
    TRADE_AND_RETAIL: 'Handel und Einzelhandel',
    HOSPITALITY_AND_TOURISM: 'Gastgewerbe und Tourismus',
    TRANSPORTATION_AND_LOGISTICS: 'Transport und Logistik',
    FINANCE_AND_INSURANCE: 'Finanzen und Versicherungen',
    INFORMATION_TECHNOLOGY: 'Informationstechnologie',
    HEALTHCARE: 'Gesundheitswesen',
    EDUCATION_AND_RESEARCH: 'Bildung und Forschung',

    // IssuerStatus
    ACTIVE: 'Aktiv',
    INACTIVE: 'Inaktiv',
    IN_LIQUIDATION: 'In Liquidation',
    INSOLVENT: 'Insolvent',
    BUSINESS_LICENSES_DELETED: 'Gewerbeberechtigungen gelöscht',

    // IssuerStatusInternal
    //ACTIVE: 'Aktiv',
    //INACTIVE: 'Inaktiv',
    SETTLED: 'Beendet',
    UNDER_OBSERVATION: 'Unter Beobachtung',

    //AssetSubStatus
    REPORTING_DELAY: 'Verzug im Reporting',
    PAYMENT_OUTSTANDING: 'Zahlung Ausständig',
    REPAID: 'Zurückbezahlt',
    EARLY_REPAYMENT: 'Vorzeitig Zurückbezahlt',

    // AgioDisagioType
    PERCENT: 'Percent',
    FIXED_VALUE: 'Fixed value',
    FIXED_VALUE_PER_SHARE: 'Fixed value per share',

    // NaturalProfileActive
    NATURAL_PROFILE_ACTIVE: 'Natürliche Person aktiv',
    NATURAL_PROFILE_DEACTIVATED: 'Natürliche Person deaktiviert',

    // VoucherType
    GIFT_VOUCHER: 'Voucher',
    LOYALTY_CARD: 'Kundenkarte',
    DISCOUNT_VOUCHER: 'Gutschein',

    // MembershipDocumentStatusType
    REQUESTED: 'Angefordert',
    SENT: 'Gesendet',

    // End of Enums //

    // Languages
    de: 'Deutsch',
    en: 'Englisch',
    fr: 'Französisch',
    it: 'Italienisch',
    pl: 'Polnisch',
    sl: 'Slowenisch',
    sk: 'Slowakisch',
    sr: 'Serbisch',

    // New translations
    help_platform_logo: 'Das Plattform Logo',
    active_emails: 'Aktive Emails',
    mail_active_acceptance_notice: 'Annahmeerklärung',
    help_mail_active_acceptance_notice:
        'Ausgehende E-Mail bezüglich der Annahmeerklärung.',
    mail_active_bank_account_information_reminder: 'IBAN Erinnerung',
    help_mail_active_bank_account_information_reminder:
        'Ausgehende E-Mail bezüglich eines fehlenden IBAN.',
    mail_active_basket_reminder: 'Offene Bestellung - Erinnerung',
    help_mail_active_basket_reminder:
        'Ausgehende E-Mail bezüglich einer offenen Bestellung.',
    mail_active_consumer_information: 'Kundeninformation',
    help_mail_active_consumer_information:
        'Ausgehende E-Mail mit Kundeninformationen.',
    mail_active_external_kyc_status_pending:
        'Externe KYC-Statusbenachrichtigung - Ausstehend',
    help_mail_active_external_kyc_status_pending:
        'Ausgehende E-Mail bezüglich eines ausstehenden externen KYC-Status.',
    mail_active_external_kyc_status_success:
        'Externe KYC-Statusbenachrichtigung - Erfolg',
    help_mail_active_external_kyc_status_success:
        'Ausgehende E-Mail bezüglich eines erfolgreichen externen KYC-Status.',
    mail_active_external_kyc_process_aborted:
        'Externer KYC-Prozess abgebrochen',
    help_mail_active_external_kyc_process_aborted:
        'Ausgehende E-Mail bezüglich eines abgebrochenen externen KYC-Prozesses.',
    mail_active_kyc_expire_reminder: 'KYC Ablauf Erinnerung',
    help_mail_active_kyc_expire_reminder:
        'Ausgehende E-Mail zur Erinnerung an ein bald ablaufendes KYC Dokument.',
    mail_active_interest_payment_announcement: 'Zinszahlung - Ankündigung',
    help_mail_active_interest_payment_announcement:
        'Ausgehende E-Mail bezüglich einer Zinszahlung.',
    mail_active_interest_payment_payout_received:
        'Zinszahlung - Auszahlung erhalten',
    help_mail_active_interest_payment_payout_received:
        'Ausgehende E-Mail bezüglich einer Zinszahlung.',
    mail_active_kyc_reminder: 'KYC-Erinnerung',
    help_mail_active_kyc_reminder:
        'Ausgehende E-Mail bezüglich eines ausstehenden KYC Prozess.',
    mail_active_kyc_status: 'KYC-Statusbenachrichtigung',
    help_mail_active_kyc_status:
        'Ausgehende E-Mail bezüglich einer KYC-Statusbenachrichtigung.',
    mail_active_no_iban_reminder: 'Ad-hoc IBAN-Reminder',
    help_mail_active_no_iban_reminder:
        'Ausgehende E-Mail bezüglich eines fehlenden IBAN.',
    mail_active_payment_confirmation: 'Zahlungsbestätigung',
    help_mail_active_payment_confirmation:
        'Ausgehende E-Mail bezüglich einer Bezahlungsbestätigung.',
    mail_active_payment_details: 'Bezahlungsdetails',
    help_mail_active_payment_details:
        'Ausgehende E-Mail bezüglich der Bezahlungsdetails.',
    mail_active_payment_reminder: 'Bezahlungserinnerung',
    help_mail_active_payment_reminder:
        'Ausgehende E-Mail bezüglich einer Bezahlungserinnerung.',
    mail_active_pre_consumer_information: 'Pre-Kundeninformation',
    help_mail_active_pre_consumer_information:
        'Ausgehende E-Mail mit Pre-Kundeninformationen.',
    mail_active_signature_reminder: 'Signatur-Erinnerung',
    help_mail_active_signature_reminder:
        'Ausgehende E-Mail bezüglich einer Signatur-Erinnerung.',
    mail_active_signature_successful: 'Signatur erfolgreich',
    help_mail_active_signature_successful:
        'Ausgehende E-Mail bezüglich einer erfolgreichen Signatur.',
    mail_active_signatures_regenerated: 'Signatur regeneriert',
    help_mail_active_signatures_regenerated:
        'Ausgehende E-Mail bezüglich einer regenerierten Signatur.',
    mail_active_voucher_received: 'Voucher erhalten',
    help_mail_active_voucher_received:
        'Ausgehende E-Mail bezüglich eines erhaltenen Vouchers.',
    mail_active_welcome: 'Willkommen',
    help_mail_active_welcome:
        'Ausgehende E-Mail bezüglich einer Willkommensmail.',
    mail_active_feedback_review: 'Feedback / Bewertung',
    help_mail_active_feedback_review: 'Anfrage um Feedbck nach Investition.',
    mail_active_voucher_balance: 'Voucher Guthaben Benachrichtigung',
    help_mail_active_voucher_balance:
        'Info über frei verfügbares Voucher Guthaben.',
    mail_active_wallet_balance: 'Wallet Guthaben Benachrichtigung',
    help_mail_active_wallet_balance:
        'Info über frei verfügbares Wallet Guthaben.',

    digital_signature_types: 'Digitale Signaturmethoden',
    require_qualified_signature: 'Qualifizierte Signaturen',
    help_require_qualified_signature: 'Nur qualifizierte Signaturen erlauben',

    qr_code_type: 'QR-Code Typ',
    payment_qr_code_type: 'Zahlungs QR-Code Typ',
    help_payment_qr_code_type: 'Der QR-Code Typ',

    payment_methods: 'Zahlungsmethoden',
    credit_card: 'Kreditkarte',
    wire_transfer: 'Banküberweisung',

    confirm_kyc_company_details: 'Bestätige KYC Firmendetails',
    reject_kyc_company_details: 'Ablehnen KYC Firmendetails',
    toast_success_update_legal_profile_kyc_legal_confirmed: 'KYC bestätigt',
    toast_success_update_legal_profile_kyc_legal_rejected: 'KYC abgelehnt',
    toast_error_update_legal_profile_kyc_legal:
        'KYC status Aktualisierung fehlgeschlagen',

    help_order_documents_table: 'Der Bestellung anhängende Dokumente.',
    help_order_reporting_documents_table:
        'Reporting Dokumente die Bestellung betreffend.',
    help_kyc_id_documents: 'Die KYC ID Dokumente.',
    help_kyc_address_documents: 'Die KYC Melde Dokumente.',
    help_kyc_bank_documents: 'Die KYC Bank Dokumente.',
    help_kyc_tax_documents: 'Die KYC Steuer Dokumente.',
    help_kyc_legal_documents:
        'KYC Dokumente zur Prüfung der Unternehmensdaten.',
    esac_kyc: 'ESAC KYC',
    esac_kyc_active: 'Esac KYC aktiv',
    help_esac_kyc_active: 'Aktivieren KYC mit ESAC',
    esac_form_natural_person: 'ESAC Formular für natürliche Personen',
    esac_form_legal_entity: 'ESAC Formular für juristische Personen',
    esac_form_legal_ubo: 'ESAC Formular für UBO',
    self_disclosure_form: 'Selbstauskunft-Formular hochladen',
    modal_title_delete_file: 'Dateien löschen?',
    modal_text_delete_file:
        'Sind Sie sicher, dass Sie die Datei {file} löschen möchten?',

    mail_type_standard: 'Standard',
    mail_template_color: 'Email Farbe',
    help_mail_template: 'Das zu verwendende E-Mail Template.',
    help_mail_template_color: 'Generelle Farbe der Plattform Emails.',
    mail_template_action_button_color: 'Email Action Button Farbe',
    help_mail_template_action_button_color:
        'Die Action Button Farbe für Plattform Emails.',
    mail_template_action_button_text_color: 'Email Action Button Text Farbe',
    help_mail_template_action_button_text_color:
        'Die Action Button Text Farbe für Plattform Emails.',

    is_realm: 'Realm',
    is_platform: 'Plattform',

    platform_assets: 'Plattform Vermögenswerte',
    help_platform_assets: 'Die Vermögenswerte der Plattform',
    toast_success_delete_asset: 'Vermögenswert erfolgreich gelöscht',
    platform_campaigns: 'Plattform Kampagnen',
    help_platform_campaigns: 'Die Kampagnen der Plattform',
    toast_success_delete_campaign: 'Kampagne erfolgreich gelöscht',
    platform_orders: 'Plattform Bestellungen',

    asset_video: 'Video URL',
    help_asset_video: 'Die Video URL der Kampagne.',

    toast_success_delete_task: 'Aufgabe erfolgreich gelöscht',

    toast_success_delete_asset_contract: 'Vertrag erfolgreich gelöscht',

    acceptance_document: 'Akzeptanz Dokument',
    acceptance_document_active: 'Aktivieren Sie das Akzeptanz Dokument',
    help_acceptance_document:
        'Das Akzeptanz Dokument wird bei der Annahme von Investments automatisch an die Investor*innen versendet.',
    help_acceptance_document_active: 'Geben Sie das Akzeptanz Dokument an.',
    help_platform_orders: 'Die Bestellungen der Plattform.',
    no_signature_link_available: 'Kein Signatur Link verfügbar',
    country_of_residence: 'Wohnort',
    natural_profile_uid: 'Natural Profile UID',
    legal_profile_uid: 'Legal Profile UID',

    upload: 'Hochladen',

    account_pep_past: 'PEP Vergangenheit',
    account_pep_position: 'PEP Position',
    help_account_pep_position:
        'Die Position, welche die politisch exponiert Person innehat.',
    account_pep_past_position: 'PEP Vergangene Position',
    help_account_pep_past_position:
        'Die Position, welche die politisch exponierte Person innehatte.',

    modal_title_duplicate_campaign: 'Duplicate campaign',

    expand: 'Vergrößern',
    collapse: 'Verkleinern',

    tasks: 'Tasks',
    help_tasks_table: 'Die Tasks Tabelle',
    taskable: 'Entität',
    result_data: 'Ergebnis',
    result_text: 'Ergebnis Text',
    expires_at: 'Läuft ab',
    download: 'Download',

    // Task Type
    GENERIC: 'Generic',
    INTEREST_PAYMENT_CALCULATION: 'Interest payment calculation',
    INTEREST_PAYMENT_PAYOUT: 'Interest payment payout',
    PAYMENT_ACCEPTANCE: 'Payment acceptance',
    EXPORT_CSV: 'Export CSV',

    // TaskStatus
    REGISTERED: 'Registriert',
    STARTED: 'Begonnen',
    FINISHED: 'Beendet',
    issuer: 'Emittent',
    issuers: 'Emittenten',
    issuer_name: 'Name',
    issuer_logo: 'Logo',
    issuer_website: 'Webseite',
    internal_status: 'Interner Status',
    issuer_details: 'Emittenten-Daten',
    toast_error_cannot_delete_issuer: 'Emittent kann nicht entfernt werden!',
    toast_success_issuer_deleted: 'Emittent "{issuer}" wurde entfernt.',
    toast_success_issuers_deleted: 'Emittenten "{issuers}" wurden entfernt.',
    toast_success_issuer_created: 'Emittent wurde erstellt!',
    help_issuers_table:
        'Die Liste der Emittenten auf die Sie zugreifen können.',
    modal_title_delete_issuer: 'Emittent löschen?',
    create_issuer: 'Emittenten erstellen',
    help_issuer: 'Emittent des Vermögenswerts',
    help_issuer_name: 'Der Name des Emittenten.',
    help_issuer_logo: 'Das Unternehmens-Logo des Emittenten.',
    issuer_status: 'Status',
    help_issuer_status: 'Der Status des Emittenten.',
    issuer_internal_status: 'Interner Status',
    help_issuer_internal_status: 'Der interne Status des Emittenten.',
    issuer_industry: 'Industrie',
    help_issuer_industry: 'Die Industrie des Emittenten.',
    toast_success_issuer_saved:
        'Emittenten {issuer} wurde erfolgreich gespeichert!',

    toast_success_export_csv:
        'Der CSV Export wird im Hintergrund erstellt. Sie werden das Ergebnis in der "Tasks" Sektion sehen, sobald dieser fertig ist.',
    platform_compliance_profile: 'Plattform Compliance Profil',
    end_contract: 'Beenden',
    modal_title_end_contract: 'Vertrag beenden?',
    modal_text_end_contract:
        'Sind Sie sicher, dass Sie diesen Vertrag vorzeitig beenden möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    contract_end_cause: 'Begründung',
    help_contract_end_cause: 'Begründung der vorzeitige Beendigung',
    toast_success_end_contract: 'Vertrag wurde erfolgreich vorzeitig beendet.',

    help_signatures_table: 'Die Signaturen.',

    beneficial_owners: 'Wirtschaftliche Eigentümer',
    help_beneficial_owners: 'Die Wirtschaftliche Eigentümer',
    show_signatures: 'Signaturen anzeigen',
    toast_success_cancel_order: 'Bestellung {order} erfolgreich abgebrochen!',

    help_open_basket_table:
        'Hier werden nicht abgeschlossene Bestellungen angezeigt',
    export_transactions: 'Transaktionen exportieren',
    toast_success_export_transactions:
        'Transaktionen wurden erfolgreich exportiert!',
    export_kista_request_kistama:
        'KISTA Anfrage exportieren (Steuer-ID vorhanden)',
    export_kista_request_kistakom:
        'KISTA Anfrage exportieren (Steuer-ID nicht vorhanden)',
    export_membership_documents: 'Mitgliedsdokumente exportieren',
    toast_success_export_kista_request: 'KISTA Anfrage erfolgreich exportiert',
    toast_success_kista_response_import:
        'KISTA Antwort erfolgreich importiert. Erzeugte Steuermerkmale: {newTaxFeaturesCount}',
    upload_kista_response: 'KISTA Antwort-CSV hochladen',

    modal_title_task_details: 'Task details',

    total_count: 'Total count',
    error_count: 'Error count',
    modal_title_duplicate_cash_contract: 'Vertrag kopieren',
    modal_text_duplicate_cash_contract: 'Vertrag kopieren?',

    has_orders: 'Has orders',

    copy_value: 'Wert kopieren',
    toast_success_copy_to_clipboard: 'Wert wurde in die Ablage kopiert!',
    toast_error_copy_to_clipboard:
        'Kopieren fehlgeschlagen. Bitte versuchen sie es erneut.',
    order_paid_date: 'Bezahlt am',
    order_acceptance_date: 'Akzeptiert am',
    order_cancellation_date: 'Abgebrochen am',

    need_help: 'Brauchen Sie Hilfe?',
    documentation: 'Dokumentation',
    report_a_bug: 'Bugreport senden',
    give_feedback: 'Feedback geben',

    resend_mail: 'E-Mail erneut senden',
    modal_title_resend_mail: 'E-Mail erneut senden?',
    help_resend_mail: 'Wählen Sie die E-Mail die sie erneut senden möchten.',

    is_main: 'Is main',

    modal_title_view_kyc_document: 'View KYC document.',
    modal_title_view_kyc_bank_document: 'View KYC Bank document.',

    created_by: 'Created by',
    payment_account_id: 'Payment account id',
    funding_total_status: 'Bestellstatus für Investitionssumme',
    webhooks_activated: 'Webhooks aktiviert',
    has_credential_simply_tokenized: 'Simply Tokenized Zugangsdaten',
    has_credential_sproof: 'Sproof Zugangsdaten',
    has_credential_voucherify: 'Voucherify Zugangsdaten',
    compliance_profile: 'Compliance Profile',
    expert_investor_treatment:
        'Als kundiger / professioneller Anleger behandelt werden?',

    updated_at: 'Bearbeitet am',
    investor_consent: 'Investor consent',
    contract_period: 'Contract period',
    asset_funding_count: 'Investitionen',
    asset_funding_total: 'Investitionssumme',
    asset_installment_investment: 'Teilzahlung',
    asset_initial_payment: 'Anfangszahlung',
    asset_installment_payout: 'Ratenzahlung',
    asset_minimum_initial_payment: 'Minimum Anfangszahlung',
    help_asset_minimum_initial_payment: 'Minimum Anfangszahlung',
    asset_maximum_initial_payment: 'Maximum Anfangszahlung',
    help_asset_maximum_initial_payment: 'Maximum Anfangszahlung',
    asset_initial_payment_step: 'Finanzierungsschritt',
    help_asset_initial_payment_step: 'Finanzierungsschritt',
    asset_installments: 'Teilzahlung',
    asset_minimum_installment: 'Minimum Teilzahlung',
    help_asset_minimum_installment: 'Minimum Teilzahlung',
    asset_maximum_installment: 'Maximum Teilzahlung',
    help_asset_maximum_installment: 'Maximum Teilzahlung',
    asset_installment_step: 'Finanzierungsschritt',
    help_asset_installment_step: 'Finanzierungsschritt',
    asset_minimum_monthly_rates: 'Min. Anzahl an Monatszahlungen',
    help_asset_minimum_monthly_rates: 'Min. Anzahl an Monatszahlungen',
    asset_maximum_monthly_rates: 'Max. Anzahl an Monatszahlungen',
    help_asset_maximum_monthly_rates: 'Max. Anzahl an Monatszahlungen',
    asset_minimum_yearly_rates: 'Min. Anzahl an Jahreszahlungen',
    help_asset_minimum_yearly_rates: 'Min. Anzahl an Jahreszahlungen',
    asset_maximum_yearly_rates: 'Max. Anzahl an Jahreszahlungen',
    help_asset_maximum_yearly_rates: 'Max. Anzahl an Jahreszahlungen',
    asset_monthly_payments: 'Monatszahlung',

    campaign_asset_needs_contract_selection: 'Asset needs contract selection',

    ended_at: 'Beendet am',
    payment_dates: 'Auszahlungstermine',
    confirmed: 'Bestätigt',

    last_login_date: 'Last login date',
    blocked_balance_from_orders: 'Blocked balance from orders',
    sso_user_uid: 'SSO User UID',
    custom_id: 'Custom id',
    sub_platform: 'Sub platform',
    email_pending: 'Email pending',
    mobile_number: 'Mobile number',
    newsletter_consent: 'Newsletter consent',
    help_contract_payment_dates_title: 'Bitte Auszahlungsdatum hinzufügen',
    help_contract_payment_dates_text:
        'Bitte geben Sie die Auszahlungsdaten an. Bitte beachten Sie, dass diese nach der ersten Auszahlung liegen müssen, und vor der letzten Auszahlung bei "Fixed" Verträgen.',

    installments: 'Installments',
    disabled_on: 'Deaktiviert am',
    current_loan_amount: 'Aktuelles Darlehen',
    paid_total: 'Gesamtsumme',
    paid_rest: 'Restbetrag',
    paid_bonus: 'Bonuszahlung',
    cumulative_rest: 'summierter Rest',
    current_rest: 'aktueller Rest',
    new_basis: 'Neue Basis',
    search: 'Search',
    global_search_placeholder: 'Global search...',
    more_search_results: 'View more results...',
    no_languages_available_for_this_email_template:
        'Keine Sprachen für diese E-Mail Vorlage verfügbar.',

    subtasks: 'Subtasks',

    ecsp_status: 'ECSP Status',
    uploaded_document: 'Hochgeladenes Dokument',
    ecsp_document: 'ECSP Dokument',
    ecsp_documents: 'ECSP Dokumente',
    ecsp_id_document: 'Hochgeladenes ECSP Dokument',
    verification_comment: 'Verifizierungskommentar',
    toast_error_ecsp_document_create:
        'ECSP document konnte nicht gespeichert werden!',
    toast_success_ecsp_document_updated:
        'ECSP document erfolgreich gespeichert!',
    button_id_ecsp_document_upload: 'ECSP Dokument hochladen',
    modal_title_create_id_ecsp_document:
        'ECSP Dokument zur Verifizierung hochladen.',
    help_ecsp_id_document:
        'In dieser Tabelle können Sie die mit dem Compliance Profile ECSP verknüpften ECSP Dokumente verwalten.',
    help_ecsp_status: 'Status der ECSP Verifizierung.',

    kyc_provider: 'KYC-Anbieter',
    the_field_contains_unallowed_characters:
        'Das Feld enthält nicht zulässige Zeichen.',

    //St. Pauli
    membership_management: 'Mitgliederverwaltung',
    membership_management_active: 'Mitgliederverwaltung aktivieren',
    help_membership_management_active:
        'Aktivieren Sie die Mitgliederverwaltung funktionalität',
    gift_membership: 'Mitgliedschaft schenkung',
    help_gift_membership:
        'Aktivieren Sie die Mitgliedschaft-schenkung funktionalität bei Bestellungen',
    gift_membership_faq: 'Mitgliedschaft schenkung FAQ',
    help_gift_membership_faq:
        'Hier können Sie den FAQ Text angeben, der im Investmentprozess beim Mitgliedschaft schenkung angezeigt werden soll.',
    gift_membership_faq_url: 'Mitgliedschaft schenkung FAQ URL',
    help_gift_membership_faq_url:
        'Diese URL wird unter Mitgliedschaft schenkung FAQ angezeigt.',
    membership_type_manual: 'Manuell',
    membership_type_sproof: 'Sproof',
    qualified_signature: 'Qualifizierte Signatur',
    help_qualified_signature: 'Qualifizierte Signatur aktivieren',
    use_rule: 'Regelwerk Annahme',
    help_use_rule:
        'Wenn dieser Schalter aktiviert ist, können nur Bestellungen von Investoren akzeptiert werden, die ein akzeptiertes Mitgliedsdokument haben.',
    order_certificate_active: 'Bestell Urkunde aktivieren',
    help_order_certificate_active: 'Aktivieren Sie die Bestell Urkunde',
    membership_number_active: 'Mitgliedsnummer aktivieren',
    help_membership_number_active:
        'Aktivieren Sie die Mitgliedsnummer bei Mitgliederverwaltung',
    membership_number_prefix_natural:
        'Mitgliedsnummer-Prefix für Natürliche Personen',
    help_membership_number_prefix_natural:
        'Sie können angeben, welcher Buchstabe mit der Mitgliedsnummer für Natürliche Personen beginnen soll',
    membership_number_prefix_legal:
        'Mitgliedsnummer-Prefix für Unternehmensprofile',
    help_membership_number_prefix_legal:
        'Sie können angeben, welcher Buchstabe mit der Mitgliedsnummer für Unternehmensprofile beginnen soll',
    help_membership_number: 'Mitgliedsnummer',
    membership_barcode_active: 'Barcode aktivieren',
    help_membership_barcode_active: 'Aktivieren Sie das Barcode funktionalität',
    upload_document: 'Dokument hochladen',
    membership_number: 'Mitgliedsnummer',
    membership_id_document: 'Hochgeladene Mitgliedsdokumente',
    help_membership_id_document:
        'Hier haben Sie die Möglichkeit, ein Mitgliedsdokument hochzuladen',
    modal_title_create_id_membership_document:
        'Neues Mitgliedsdokument hochladen',
    membership_document_description: 'Mitgliedsdokument Beschreibung',
    button_id_membership_document_upload: 'Dokument auswählen',
    modal_title_update_membership_document: 'Mitgliedsdokuement bearbeiten',
    membership_document_status: 'Status des Mitgliedsdokuments',
    membership_documents_admin_email: 'Mitgliedsdokuement Admin E-mail',
    help_membership_document_status:
        'Der Status des Mitgliedsdokuments kann 3 Werte haben: ausstehend, akzeptiert und abgelehnt',
    help_membership_document_description:
        'Hier können Sie eine Beschreibung für das Mitgliedsdokument eingeben. Wird für die Kunden in bereich Mitgliedsdokuement angezeigt. Dies soll den Kunden helfen, zu wissen, was mit dem Dokument zu tun ist.',
    has_membership: 'Mitglied',
    hasnt_membership: 'Nicht mitglied',
    modal_title_view_membership_document: 'Mitgliedsdokument',
    toast_success_membership_document_updated:
        'Mitgliedsdokument wurde erfolgreich gespeichert!',
    toast_error_membership_document_updated:
        'Mitgliedsdokument konnte nicht gespeichert werden!',
    toast_error_platform_saving_membership_document_missing:
        'Plattform mit aktiver Mitgliederverwaltung ohne Mitgliedsdokument kann nicht gespeichert werden!',
    toast_success_membership_document_create:
        'Mitgliedsdokument wurde erfolgreich gespeichert!',
    toast_error_membership_document_create:
        'Mitgliedsdokument konnte nicht gespeichert werden!',
    toast_success_membership_document_approved:
        'Mitgliedsdokument wurde erfolgreich akzeptiert!',
    toast_error_membership_document_approved:
        'Mitgliedsdokument konnte nicht akzeptiert werden!',

    // Certificate
    order_certificate: 'Bestell Urkunde',

    upload_nyala_registry_extract: 'Upload Nyala Registry Extract',
    toast_success_upload_nyala_registry_extract:
        'Documents uploaded successfully for the following customers: {externalCustomerIds}.',
    send_nyala_registry_document_email: 'Send Nyala Registry Document email',
    toast_success_send_nyala_registry_document_email:
        'The Nyala Registry Document email was successfully sent.',
    add: 'Hinzufügen',
    days: 'Tage',
    hours: 'Stunden',
    minutes: 'Minuten',
    toast_success_kyc_processes_reset: 'KYC Prozesse wurden zurückgesetzt!',
    toast_error_kyc_processes_reset:
        'KYC Prozesse konnten nicht zurückgesetzt werden!',
    EMAIL_TEMPLATE_NYALA_REGISTRY_FILE: 'Send Nyala Registry Documents',
    nyala_registry_documents: 'Nyala Registry Documents',
    nyala_registry_documents_help: 'The Nyala Registry Documents',
    nyala_registry_document: 'Nyala Registry Document',
    post_status: 'Lieferstatus',
    issuer_id: 'Emittent ID',
    asset_uid: 'Vermögenswert ID',
    campaign_id: 'Kampagnen ID',
    contract_uid: 'Vertrags ID',
    is_gift: 'Geschenk',
}
