import { UserPermissions } from '@src/types'

export const userPermissions: UserPermissions = {
    see_id_columns: ['developer', 'superadmin'],
    access_dashboard: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    view_dashboard: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
        'read_only',
    ],
    view_global_platforms: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'read_only',
    ],
    view_platforms: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
        'read_only',
    ],
    view_esac_kyc_active: ['superadmin', 'developer'],
    create_platform: ['superadmin', 'developer'],
    update_platform: ['superadmin', 'developer', 'platform_admin'],
    delete_platform: ['superadmin', 'developer'],
    view_assets: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
        'read_only',
    ],
    create_asset: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    update_asset: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    delete_asset: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    view_issuers: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
        'read_only',
    ],
    create_issuer: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    update_issuer: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    delete_issuer: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    delete_asset_without_orders: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    view_campaigns: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
        'read_only',
    ],
    create_campaign: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    update_campaign: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    change_campaign_status: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    delete_campaign: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    view_accounts: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
        'read_only',
    ],
    update_account: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    delete_account: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    create_account: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    view_orders: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
        'read_only',
    ],
    create_order: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    update_order: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    delete_order: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
    ],
    view_payments: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
        'read_only',
    ],
    update_payments: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    view_contracts: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
        'read_only',
    ],
    update_contract: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    create_contract: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    delete_contract: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    end_contract: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    add_contract_to_asset: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    associate_orders: ['superadmin', 'developer'],
    perform_interest_payments: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    cancel_transactions: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    account_perform_money_out: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    view_activity_log: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    view_exports: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    trigger_accounts_export: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    trigger_orders_export: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    view_roles_and_permissions: ['superadmin', 'developer', 'platform_admin'],
    create_role: ['superadmin', 'developer'],
    update_custom_role: ['superadmin', 'developer'],
    change_role_permissions: ['superadmin', 'developer'],
    delete_custom_role: ['superadmin', 'developer'],
    trigger_ad_hoc_iban_reminder: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    transfer_money: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    gdpr_deletion: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    create_edit_asset_company: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    bulk_change_orders_status: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
    ],
    bulk_change_orders_campaign: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
    ],
    view_email_audits: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
    ],
    change_order_status_date: [
        'superadmin',
        'developer',
        'backoffice_manager_conda',
    ],
    resend_order_email: ['superadmin', 'developer'],
    view_transactions: ['superadmin', 'developer'],
    sync_wallet: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    view_tasks: [
        'superadmin',
        'developer',
        'platform_admin',
        'frontoffice_manager',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    delete_tasks: ['superadmin', 'developer'],
    manage_manual_funding_totals: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
    ],
    update_company_email: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
    ],
    set_kyc_provider: ['superadmin', 'developer'],
    can_reset_external_kyc_process: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
        'backoffice_manager_wl',
    ],
    upload_nyala_registry_extract: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
    ],
    send_nyala_registry_document_email: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
    ],
    view_nyala_registry_document_email: [
        'superadmin',
        'developer',
        'platform_admin',
        'backoffice_manager_conda',
    ],
    filter_by_id: ['superadmin', 'developer'],
}
