<template>
    <select
        v-model="$i18n.locale"
        name="lang"
        class="rounded-xl border-none bg-gray-100 text-sm !outline-none !ring-0"
    >
        <option value="de">🇦🇹</option>
        <option value="en">🇬🇧</option>
    </select>
</template>

<script setup lang="ts">
import Cookies from 'js-cookie'
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()

watch(locale, (newLocale) => {
    Cookies.set('language', newLocale, { expires: 365 })
})
</script>
