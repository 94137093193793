<template>
    <AccountsTable>
        <template #actions>
            <CreateButton
                v-if="userStore.can('create_account')"
                title="create_natural_account"
                @click.stop="openCreateNaturalAccount"
            />
        </template>
        <template #filters>
            <DropdownFilter
                :pin="pinFilters"
                @apply="applyFilters"
                @clear="clearFilters"
                @toggle-pin="togglePinFilters"
            >
                <DropdownFilterItem v-if="userStore.can('filter_by_id')">
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.uid"
                            name="uid"
                            :placeholder="$t('id')"
                            :disabled="disableFilter('uid')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.platform_name_exact"
                            v-model:options="platformsSelectOptions"
                            name="platform"
                            :placeholder="$t('platform')"
                            :disabled="disableFilter('platform_name_exact')"
                            :on-search="loadPlatformsSelectOptions"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.account_title"
                            name="account_title"
                            :placeholder="$t('account_title')"
                            :disabled="disableFilter('account_title')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.email"
                            name="account_email"
                            :placeholder="$t('account_email')"
                            :disabled="disableFilter('email')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.active"
                            name="active"
                            :options="mapEnumToArray(NaturalProfileActive)"
                            :reduce="reduceEnumValue"
                            :placeholder="$t('active')"
                            :disabled="disableFilter('active')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.wallet_status"
                            name="wallet_status_title"
                            :options="mapEnumToArray(WalletStatus)"
                            :reduce="reduceEnumValue"
                            :placeholder="$t('wallet_status_title')"
                            :disabled="disableFilter('wallet_status_title')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.wallet_id"
                            name="wallet_id"
                            :placeholder="$t('wallet_id')"
                            :disabled="disableFilter('wallet_id')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.account_type"
                            name="account_type"
                            :options="mapEnumToArray(AccountType)"
                            :reduce="reduceEnumValue"
                            :placeholder="$t('account_type')"
                            :disabled="disableFilter('account_type')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.external_kyc_process_status"
                            name="external_kyc_process_status"
                            :options="mapEnumToArray(ExternalKycStatus)"
                            :reduce="reduceEnumValue"
                            :placeholder="$t('external_kyc_process_status')"
                            :disabled="
                                disableFilter('external_kyc_process_status')
                            "
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.external_kyc_id"
                            name="external_kyc_id"
                            :placeholder="$t('kyc_external_external_id')"
                            :disabled="disableFilter('external_kyc_id')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.ecsp_status"
                            name="ecsp_status"
                            :options="mapEnumToArray(EcspStatusType)"
                            :reduce="reduceEnumValue"
                            :placeholder="$t('ecsp_status')"
                            :disabled="disableFilter('ecsp_status')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>

                <!-- St. Pauli -->
                <DropdownFilterItem v-if="userStore.can('see_id_columns')">
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.membership_number"
                            name="membership_number"
                            :placeholder="$t('membership_number')"
                            :disabled="disableFilter('membership_number')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.has_membership"
                            name="has_membership"
                            :options="[
                                { label: $t('yes'), value: true },
                                { label: $t('no'), value: false },
                            ]"
                            :reduce="reduceEnumValue"
                            :placeholder="$t('has_membership')"
                            :disabled="disableFilter('has_membership')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>
            </DropdownFilter>
        </template>
        <template #menu>
            <ActionsMenu v-if="userStore.can('trigger_accounts_export')" />
        </template>
    </AccountsTable>

    <CreateNaturalAccountModal
        v-if="createNaturalProfileModalOpen"
        ref="createNaturalAccountModal"
        v-model="tmpNaturalProfile"
        v-model:open="createNaturalProfileModalOpen"
        :platforms="platforms"
        :disabled-fields="createDisabledFields"
        @submit="createNaturalAccount"
        @cancel="closeCreateNaturalAccountModal"
    />
</template>

<script setup lang="ts">
import { useActionsMenu, useEnum } from '@composables/common'
import CreateNaturalAccountModal from '@partials/accounts/CreateNaturalAccountModal.vue'
import {
    DropdownFilter,
    DropdownFilterItem,
    SelectField,
    TextField,
} from '@src/components'
import {
    AccountType,
    EcspStatusType,
    ExternalKycStatus,
    NaturalProfileActive,
    WalletStatus,
} from '@src/enums'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import { Account, NaturalProfile, Platform, TableSorting } from '@src/types'
import {
    createAccountForNaturalProfile as apiCreateNaturalProfile,
    apiDownloadAccountsCsv,
    getAccounts,
} from '@src/utils/api/accounts'
import { getPlatforms } from '@src/utils/api/platforms'
import { useUserStore } from '@src/utils/stores/user'
import { useToastStore } from '@utils/stores/toast'
import { Ref, VNode, h, onMounted, ref, shallowRef, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'

import {
    faBuildingColumns,
    faCircleCheck,
    faCircleXmark,
    faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PlatformNameCellRenderer from '@partials/platforms/PlatformNameCellRenderer.vue'
import { useTable } from '@src/composables/common'
import CopyButton from '@src/partials/actions/CopyButton.vue'
import { handleEnterPress } from '@src/utils/helpers'
import moment from 'moment'
import { useRouter } from 'vue-router'

const props = withDefaults(
    defineProps<{
        initialFilters?: {
            account_title?: string | null
            email?: string | null
            platform_name_exact?: string | null
            wallet_status?: string | null
            wallet_id?: string | null
        } | null
        createInitialValues?: NaturalProfile | null
        createDisabledFields?: string[] | null
    }>(),
    {
        initialFilters: null,
        createInitialValues: null,
        createDisabledFields: null,
    }
)

const { initialFilters, createInitialValues, createDisabledFields } =
    toRefs(props)

const { mapEnumToArray, reduceEnumValue } = useEnum()
const router = useRouter()
const toast = useToastStore()
const accountsCount = ref<number>(0)
const { getEnumLabel } = useEnum()
const accounts: Ref<Account[]> = ref([])
const i18n = useI18n()
const count = ref<number>(0)
const userStore = useUserStore()

const createNaturalProfileModalOpen = ref(false)
const tmpNaturalProfile = ref<NaturalProfile>(new NaturalProfile())
const platforms = ref<Platform[]>([])
const platformsSelectOptions = ref<string[]>([])

const { ActionsMenu } = useActionsMenu(
    {
        id: 'accounts-actions-menu',
        align: 'right',
    },
    [
        {
            label: 'export_accounts_csv',
            icon: 'fa-file-csv',
            color: 'text-blue-600',
            action: downloadAccountsCsv,
            condition: () => userStore.can('trigger_accounts_export'),
        },
    ]
)

const disableFilter = (filter: string) => {
    if (initialFilters.value) {
        return (
            Object.keys(initialFilters.value).filter((item) => {
                return item === filter
            }).length > 0
        )
    }
    return false
}

const {
    TableWrapper: AccountsTable,
    limit,
    sorting,
    page,
    filtersFields,
    filters,
    pinFilters,
    togglePinFilters,
    setFilters,
    applyFilters,
    clearFilters,
} = useTable({
    loadData: loadAccounts,
    filters: {
        account_title: null,
        email: null,
        active: null,
        platform_name_exact: null,
        wallet_status: null,
        wallet_id: null,
        search: null,
    },
    disableFilters: initialFilters.value
        ? Object.keys(initialFilters.value)
        : [],
    tableProps: {
        id: 'accounts-table',
        label: 'accounts',
        help: 'help_accounts_table',
        pagination: true,
        data: accounts,
        total: accountsCount,
        sorting: ref<TableSorting[]>([
            {
                field: 'created_on',
                direction: 'desc',
            },
        ]),
        columns: [
            {
                label: 'account_uid',
                key: 'account_uid',
                select: true,
            },
            {
                label: 'account_type',
                key: 'account_type',
                sorting: true,
                center: true,
                cellRenderer: (props: { rowData: Account }) => {
                    if (props.rowData.account_type === 'legal') {
                        return h(FontAwesomeIcon, {
                            icon: faBuildingColumns,
                            class: 'text-xl',
                            title: i18n.t('legal_account'),
                        })
                    } else {
                        return h(FontAwesomeIcon, {
                            icon: faUser,
                            class: 'text-xl',
                            title: i18n.t('natural_account'),
                        })
                    }
                },
            },
            {
                label: 'id',
                key: 'uid',
                sorting: false,
                condition: userStore.can('see_id_columns'),
                cellRenderer: (props: { rowData: Account }): VNode | null => {
                    let id: string | null = ''
                    if (props.rowData.account_type === 'legal') {
                        id = props.rowData.legal_profile_uid
                    } else if (props.rowData.account_type === 'natural') {
                        id = props.rowData.natural_profile_uid
                    }

                    const url = accountTableUrl(props.rowData)

                    return id
                        ? h(
                              'div',
                              {
                                  class: 'flex items-center',
                              },
                              [
                                  h(
                                      'a',
                                      {
                                          href: url,
                                          class: 'text-blue-500',
                                      },
                                      id
                                  ),
                                  h(CopyButton, {
                                      value: id,
                                  }),
                              ]
                          )
                        : null
                },
            },
            {
                label: 'active',
                key: 'active',
                center: true,
                cellRenderer: (props: { rowData: Account }) => {
                    if (props.rowData?.active === true) {
                        return h(FontAwesomeIcon, {
                            icon: faCircleCheck,
                            class: 'text-success-400 text-xl',
                            title: i18n.t('NATURAL_PROFILE_ACTIVE'),
                        })
                    } else if (props.rowData?.active === false) {
                        return h(FontAwesomeIcon, {
                            icon: faCircleXmark,
                            class: 'text-danger-400 text-xl',
                            title: i18n.t('NATURAL_PROFILE_DEACTIVATED'),
                        })
                    }
                },
            },
            {
                label: 'has_membership',
                key: 'membership_number',
                center: true,
                cellRenderer: (props: { rowData: Account }) => {
                    if (props.rowData?.membership_number !== null) {
                        return h(FontAwesomeIcon, {
                            icon: faCircleCheck,
                            class: 'text-success-400 text-xl',
                            title: i18n.t('has_membership'),
                        })
                    } else {
                        return h(FontAwesomeIcon, {
                            icon: faCircleXmark,
                            class: 'text-danger-400 text-xl',
                            title: i18n.t('hasnt_membership'),
                        })
                    }
                },
            },
            {
                label: 'membership_number',
                key: 'membership_number',
                sorting: true,
                copy: true,
            },
            {
                label: 'account_title',
                key: 'account_title',
                url: accountTableUrl,
                sorting: true,
                copy: true,
            },
            {
                label: 'platform',
                key: 'platform_name',
                sorting: true,
                cellRenderer: shallowRef(PlatformNameCellRenderer),
            },
            {
                label: 'email',
                key: 'email',
                sorting: true,
                copy: true,
            },
            {
                label: 'wallet_status',
                key: 'wallet_status',
                sorting: true,
                cellRenderer: (props: { rowData: Account }) => {
                    if (
                        props.rowData.wallet_status === undefined ||
                        props.rowData.wallet_status === null
                    ) {
                        return i18n.t('wallet_not_yet_created')
                    } else {
                        return getEnumLabel(
                            WalletStatus,
                            props.rowData.wallet_status
                        )
                    }
                },
            },
            {
                label: 'wallet_id',
                key: 'wallet_id',
                sorting: false,
            },
            {
                label: 'kyc_external_external_id',
                key: 'external_kyc_id',
                sorting: false,
            },
            {
                label: 'ecsp_status',
                key: 'ecsp_status',
                sorting: false,
                cellRenderer: (props: { rowData: Account }) => {
                    return getEnumLabel(
                        EcspStatusType,
                        props.rowData.ecsp_status
                    )
                },
            },
            {
                label: 'created_on',
                key: 'created_on',
                sorting: true,
                cellRenderer: (props: { rowData: Account }) => {
                    return moment(props.rowData.created_on).format(
                        'DD.MM.yyyy HH:mm:ss'
                    )
                },
            },
        ],
        actions: [
            {
                action: (account: Account) =>
                    handleAccountAction(account, 'view'),
                icon: 'fa-eye',
                title: 'view',
                condition: userStore.can('view_accounts'),
            },
            {
                action: (account: Account) =>
                    handleAccountAction(account, 'edit'),
                icon: 'fa-solid fa-pen',
                title: 'edit',
                condition: userStore.can('update_account'),
            },
        ],
    },
})

function handleAccountAction(account: Account, actionType: string) {
    const viewOrEdit = actionType === 'edit' ? '.edit' : ''

    if (account.account_type === 'legal') {
        router.push({
            name: 'legalProfile' + viewOrEdit,
            params: { id: account.legal_profile_uid },
        })
    } else if (account.account_type === 'natural') {
        router.push({
            name: 'naturalProfile' + viewOrEdit,
            params: { id: account.natural_profile_uid },
        })
    }
}

function accountTableUrl(account: Account) {
    if (account.account_type === 'legal') {
        return '/legalProfile/' + account.legal_profile_uid
    } else if (account.account_type === 'natural') {
        return '/naturalProfile/' + account.natural_profile_uid
    }

    return ''
}

async function downloadAccountsCsv() {
    await apiDownloadAccountsCsv(filters.value)
        .catch((error) => {
            throw error
        })
        .then(() => {
            toast.success(i18n.t('toast_success_export_csv'))
        })
}

async function loadPlatformsSelectOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformsSelectOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

async function loadAccounts() {
    const accountRequest = await getAccounts(
        filters.value,
        page.value,
        limit.value,
        sorting.value
    )
    accounts.value = accountRequest.data.accounts
    count.value = accountRequest.data.accounts_count
    accountsCount.value = accountRequest.data.accounts_count
}

function openCreateNaturalAccount(): void {
    if (createInitialValues.value) {
        tmpNaturalProfile.value = {
            ...tmpNaturalProfile.value,
            ...createInitialValues.value,
        }
    }
    createNaturalProfileModalOpen.value = true
}

function closeCreateNaturalAccountModal() {
    tmpNaturalProfile.value = new NaturalProfile()
    createNaturalProfileModalOpen.value = false
}

async function createNaturalAccount(next?: string) {
    const createNaturalProfile = await apiCreateNaturalProfile(
        tmpNaturalProfile.value
    )

    const profile_id =
        createNaturalProfile.data.natural_profile.natural_profile_uid

    createNaturalProfileModalOpen.value = false

    if (next && next == 'edit') {
        router.push({
            name: 'naturalProfile.edit',
            params: { id: profile_id },
        })
    } else if (next && next == 'view') {
        router.push({
            name: 'naturalProfile',
            params: { id: profile_id },
        })
    } else {
        page.value = 1
        limit.value = parseInt(import.meta.env.VITE_DEFAULT_TABLE_LIMIT)
        await loadAccounts()
    }

    // clear new platform object
    tmpNaturalProfile.value = new NaturalProfile()

    toast.success(i18n.t('toast_success_account_created'))
}

onMounted(async () => {
    if (initialFilters.value) {
        filtersFields.value = {
            ...filtersFields.value,
            ...initialFilters.value,
        }

        if (filtersFields.value.platform_name_exact) {
            await loadPlatformsSelectOptions(
                filtersFields.value.platform_name_exact
            )
        }
        setFilters()
    }
    loadAccounts()
})
</script>
<style lang="scss" scoped>
input {
    &:disabled {
        @apply disabled:bg-gray-100;
    }
}
</style>
