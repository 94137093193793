import { i18n } from '@src/utils/lang'
import { getCurrencySymbol } from '@utils/helpers'
const { locale } = i18n.global

export const formatNumericValues = (value: number, currency?: string) => {
    if (typeof value !== 'number') {
        return
    }

    const formattedValue = value.toLocaleString(locale.value)

    if (currency) {
        return `${getCurrencySymbol(locale.value, currency)} ${formattedValue}`
    }

    return formattedValue
}
