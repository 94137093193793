<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-cart-shopping" />
            {{ $t('order') }} {{ order.account_title }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/orders'"
                icon="fa-list"
                title="orders"
                @click="toOrders"
            />
            <EditButton
                v-if="userStore.can('update_order')"
                @click="editOrder"
            />
            <ActionsMenu v-if="showActionMenu" />
        </template>

        <SummaryWrapper>
            <OrderSummary />
            <template #sidebar>
                <AccountSummary />
                <CampaignSummary />
                <AssetSummary />
                <PlatformSummary v-if="userStore.is('developer')" />
            </template>
        </SummaryWrapper>

        <AccordionBasic
            v-if="
                order.asset_data?.clearing_system === ClearingSystem['EMISSION']
            "
            title="additional_fields"
            :open="false"
        >
            <AdditionalFieldsSummary />
        </AccordionBasic>

        <AccordionBasic
            v-if="order.needs_signature"
            title="order_emission_signatures"
            :add-to-title="orderSignatures.length"
            class="mb-2"
            :open="false"
        >
            <SignaturesTable v-if="orderSignatures.length > 0" />
            <div v-else>
                {{ $t('no_signatures') }}
            </div>
            <div class="mt-4 flex gap-x-2">
                <button
                    class="btn-xs bg-primary-additional-500 hover:bg-primary-additional-600 text-white"
                    @click="confirmSignature"
                >
                    {{ $t('confirm_manual_signature') }}
                </button>
                <button
                    v-if="signaturesToRegenerate"
                    class="btn-sm bg-danger-500 hover:bg-danger-600 text-white"
                    @click="regenerateSignature"
                >
                    {{ $t('regenerate_signatures') }}
                </button>
            </div>
        </AccordionBasic>

        <OrderAttachmentsTable>
            <template #actions>
                <CreateButton
                    v-if="userStore.can('update_order')"
                    @click.stop="handleUpdateOrCreateOrderAttachment"
                />
                <DeleteBulkButton
                    v-if="userStore.can('update_order')"
                    v-model:selected="selectedOrderAttachmentsIds"
                    @click.stop="handleDeleteOrderAttachments"
                />
            </template>
        </OrderAttachmentsTable>
        <OrderDocumentsTable />
        <OrderReportingDocumentsTable />
        <OrderCertificateTable v-if="orderCertificate.length" />

        <OrderInterestPaymentTransactionsSection
            v-if="order.order_uid"
            :order-id="order.order_uid"
            @get-count="getInterestPaymentsCount"
            @update="loadOrder"
        />

        <template #modals>
            <SetOrderStatusModal
                ref="setOrderStatusModal"
                v-model="order"
                v-model:open="setOrderStatusModalOpen"
                :title="statusModalTitle"
                :to-status="changeToStatus"
                @submit="setOrderStatus"
                @cancel="closeSetOrderStatusModal"
            />
            <CancelOrderModal
                ref="setOrderStatusModal"
                v-model="order"
                v-model:open="cancelOrderModalOpen"
                :title="statusModalTitle"
                :to-status="changeToStatus"
                @submit="cancelOrder"
                @cancel="closeSetOrderStatusModal"
            />
        </template>
        <ModalWrapper v-if="isModalOpen" />
    </PageWrapper>
</template>

<script setup lang="ts">
/* eslint-disable quote-props */
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import {
    Asset,
    Campaign,
    EmissionSignature,
    LegalProfile,
    NaturalProfile,
    Order,
    OrderAttachment,
    OrderCertificate,
    OrderDocument,
    Platform,
    ReportingDocument,
} from '@src/types'
import { computed, h, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import {
    ModalButtonStyles,
    useActionsMenu,
    useEnum,
    useModal,
} from '@composables/common'
import { useOrderAttachmentsTable } from '@composables/orders/useOrderAttachmentsTable'
import { useOrderDocumentsTable } from '@composables/orders/useOrderDocumentsTable'
import { useOrderReportingDocumentsTable } from '@composables/orders/useOrderReportingDocumentsTable'
import { useAccountSummary } from '@composables/summaries/useAccountSummary'
import { useCampaignSummary } from '@composables/summaries/useCampaignSummary'
import { usePlatformSummary } from '@composables/summaries/usePlatformSummary'
import CreateButton from '@partials/actions/CreateButton.vue'
import DeleteBulkButton from '@partials/actions/DeleteBulkButton.vue'
import OrderInterestPaymentTransactionsSection from '@partials/orders/OrderInterestPaymentTransactionsSection.vue'
import {
    AccordionBasic,
    Datepicker,
    SelectField,
    SummaryWrapper,
} from '@src/components'
import { useSummary } from '@src/composables/common'
import { useSignaturesTable } from '@src/composables/signatures/useSignaturesTable'
import { useAssetSummary } from '@src/composables/summaries/useAssetSummary'
import { ClearingSystem, OrderStatus } from '@src/enums'
import { PlatformEmailTemplateType } from '@src/enums/Platform'
import BackButton from '@src/partials/actions/BackButton.vue'
import EditButton from '@src/partials/actions/EditButton.vue'
import {
    default as CancelOrderModal,
    default as SetOrderStatusModal,
} from '@src/partials/orders/SetOrderStatusModal.vue'
import { SummaryItem } from '@src/types/Summary'
import { getLegalProfile, getNaturalProfile } from '@src/utils/api/accounts'
import { getAsset } from '@src/utils/api/assets'
import { getCampaign } from '@src/utils/api/campaigns'
import {
    ChangeOrderStatusDatePayload,
    cancelOrder as apiCancelOrder,
    changeOrderStatusDate as changeOrderStatusDateApi,
    getOrder,
    resendEmail,
    updateOrder,
} from '@src/utils/api/orders'
import { getPlatform } from '@src/utils/api/platforms'
import { formatNumericValues } from '@src/utils/helpers'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { useOrderCertificateTable } from '@composables/orders/useOrderCertificateTable'

const order = ref<Order>(new Order())
const orderAttachments = ref<OrderAttachment[]>([])
const orderDocuments = ref<OrderDocument[]>([])
const orderReportingDocuments = ref<ReportingDocument[]>([])
const orderCertificate = ref<OrderCertificate[]>([])
const orderSignatures = ref<EmissionSignature[]>([])
const asset = ref<Asset>(new Asset())
const platform = ref<Platform>(new Platform())
const naturalProfile = ref<NaturalProfile>(new NaturalProfile())
const legalProfile = ref<LegalProfile>(new LegalProfile())
const campaign = ref<Campaign>(new Campaign())
const interestPaymentsCount = ref<number>(0)
const dataChanged = ref<boolean>(false)
const setOrderStatusModalOpen = ref(false)
const cancelOrderModalOpen = ref(false)
const showLegalProfile = ref(false)
const statusModalTitle = ref<string>('')
const { getEnumLabel } = useEnum()
const changeToStatus = ref<number>(OrderStatus.CANCELED)
const emailToResend = ref<string | null>(null)
const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()
const userStore = useUserStore()

const { ModalWrapper, openModal } = useModal()
const isModalOpen = ref(false)

/* @ts-ignore */
const orderOverviewItems = computed<SummaryItem[]>(() => {
    return [
        {
            label: i18n.t('order_id'),
            value: order.value.order_uid,
            copy: true,
        },
        {
            label: i18n.t('total'),
            value:
                order.value.total_amount != null
                    ? formatNumericValues(
                          order.value.total_amount,
                          order.value.asset_data?.currency || ''
                      )
                    : '-',
        },
        {
            label: i18n.t('invested_amount'),
            value:
                order.value.total_amount != null
                    ? formatNumericValues(
                          order.value.total_amount,
                          order.value.asset_data?.currency || ''
                      )
                    : '-',
        },
        {
            label: i18n.t('wallet_amount'),
            value:
                order.value.wallet_amount != null
                    ? formatNumericValues(
                          order.value.wallet_amount,
                          order.value.asset_data?.currency || ''
                      )
                    : '-',
        },
        {
            label: i18n.t('voucher_amount'),
            value:
                order.value.voucher_amount != null
                    ? formatNumericValues(
                          order.value.voucher_amount,
                          order.value.asset_data?.currency || ''
                      )
                    : '-',
            condition: order.value.voucher_amount !== null,
        },
        {
            label: i18n.t('voucher_code'),
            value: order.value.voucher_code,
            condition: order.value.voucher_code !== null,
        },
        {
            label: i18n.t('voucher_rate'),
            value: order.value.voucher_rate,
            condition: order.value.voucher_rate !== null,
        },
        {
            label: i18n.t('total_amount_to_pay'),
            value: formatNumericValues(
                order.value.total_amount_to_pay as number,
                order.value.asset_data?.currency || ''
            ),
            condition: order.value.total_amount_to_pay !== null,
        },
        {
            label: i18n.t('status'),
            value: getEnumLabel(OrderStatus, order.value.status),
        },
        {
            label: i18n.t('incentive'),
            value: order.value.incentive?.incentives_title,
            condition: !!order.value.incentive?.incentives_title,
        },
        {
            label: i18n.t('date_of_order'),
            value: moment(order.value.created_on).format('DD.MM.yyyy HH:mm'),
        },
        {
            label: i18n.t('order_paid_date'),
            value: moment(order.value.paid_date).format('DD.MM.yyyy HH:mm'),
            condition: order.value.paid_date,
        },
        {
            label: i18n.t('order_acceptance_date'),
            value: moment(order.value.investment_acceptance_date).format(
                'DD.MM.yyyy HH:mm'
            ),
            condition: order.value.investment_acceptance_date,
        },
        {
            label: i18n.t('order_cancellation_date'),
            value: moment(order.value.cancellation_date).format(
                'DD.MM.yyyy HH:mm'
            ),
            condition: order.value.cancellation_date !== null,
        },
        {
            label: i18n.t('order_was_created_manually'),
            value: order.value.is_manual ? i18n.t('yes') : i18n.t('no'),
        },
        {
            label: i18n.t('reference_number'),
            value: order.value.reference_number,
            copy: true,
            condition: order.value.reference_number !== null,
        },
        {
            label: i18n.t('order_emission_signature'),
            value: order.value.has_signature,
            special: 'check',
        },
        {
            label: i18n.t('broker_status'),
            value: order.value.broker?.broker_status,
            special: 'check',
        },
        {
            label: i18n.t('broker_disclaimer'),
            value: order.value.broker?.broker_disclaimer,
            condition: order.value.broker?.broker_disclaimer,
        },
        {
            label: i18n.t('investor_consent'),
            value: order.value.investor_consent,
            special: 'check',
            condition: order.value.investor_consent !== null,
        },
        {
            label: i18n.t('installments'),
            value: order.value.installments,
            condition: order.value.installments !== null,
        },
        {
            label: i18n.t('savings_plan'),
            value: order.value.savings_plan,
            special: 'check',
        },
        {
            label: i18n.t('is_gift'),
            value: order.value.is_gift,
            special: 'check',
        },
        {
            label: i18n.t('created_at'),
            value: moment(order.value.created_at).format('DD.MM.yyyy HH:mm'),
        },
        {
            label: i18n.t('updated_at'),
            value: moment(order.value.updated_at).format('DD.MM.yyyy HH:mm'),
        },
        {
            label: i18n.t('disabled_on'),
            value: moment(order.value.disabled_on).format('DD.MM.yyyy HH:mm'),
            condition: order.value.disabled_on !== null,
        },
    ]
})

const { Summary: OrderSummary } = useSummary(orderOverviewItems, {
    title: 'summary',
})

const { AccountSummary } = useAccountSummary(naturalProfile, legalProfile)

const { AssetSummary } = useAssetSummary(asset)

const { CampaignSummary } = useCampaignSummary(campaign)

const { PlatformSummary } = usePlatformSummary(platform)

const additionalFieldsItems = computed<SummaryItem[]>(() => {
    return [
        {
            label: i18n.t('depot_owner'),
            value: order.value.additional_fields?.depot_owner,
        },
        {
            label: i18n.t('depot_number'),
            value: order.value.additional_fields?.depot_number,
        },
        {
            label: i18n.t('depot_bank'),
            value: order.value.additional_fields?.depot_bank,
        },
        {
            label: i18n.t('depot_bic'),
            value: order.value.additional_fields?.depot_bic,
        },
    ]
})

const { Summary: AdditionalFieldsSummary } = useSummary(additionalFieldsItems, {
    title: 'additional_fields',
})

const { SignaturesTable } = useSignaturesTable(orderSignatures)

const {
    OrderAttachmentsTable,
    selectedOrderAttachmentsIds,
    handleDeleteOrderAttachments,
    handleUpdateOrCreateOrderAttachment,
} = useOrderAttachmentsTable(orderAttachments, update, openModal, isModalOpen)

const { OrderDocumentsTable } = useOrderDocumentsTable(orderDocuments)

const { OrderReportingDocumentsTable } = useOrderReportingDocumentsTable(
    orderReportingDocuments
)

const { OrderCertificateTable } = useOrderCertificateTable(orderCertificate)

function getInterestPaymentsCount(count: number) {
    interestPaymentsCount.value = count
}

async function update(successMessage: string | null = null) {
    const updateOrderResponse = await updateOrder(order.value)
    if (updateOrderResponse.data.success) {
        if (successMessage) {
            toast.success(successMessage)
        }
    }
    await loadOrder()
}

async function loadOrder() {
    const orderRequest = await getOrder(route.params.id as string)
    order.value = orderRequest.data.order
    orderAttachments.value = order.value.attachments
    orderDocuments.value = order.value.order_documents
    orderReportingDocuments.value = order.value.reporting_documents
    orderSignatures.value = order.value.emission_signatures
    orderCertificate.value = order.value.certificate

    if (orderRequest.data.order.asset_var_uid) {
        const campaignRequest = await getCampaign(
            orderRequest.data.order.asset_var_uid
        )
        campaign.value = campaignRequest.data.campaign

        if (campaignRequest.data.campaign.asset_uid) {
            const assetRequest = await getAsset(
                campaignRequest.data.campaign.asset_uid
            )
            asset.value = assetRequest.data.asset
        }
    }

    if (orderRequest.data.order.natural_profile_id !== null) {
        const naturalProfileRequest = await getNaturalProfile(
            orderRequest.data.order.natural_profile_id
        )
        naturalProfile.value = naturalProfileRequest.data.natural_profile

        if (
            naturalProfileRequest.data.natural_profile.first_name +
                ' ' +
                naturalProfileRequest.data.natural_profile.last_name !==
            orderRequest.data.order.account_title
        )
            dataChanged.value = true
    }

    showLegalProfile.value = !!order.value.legal_profile_id

    if (orderRequest.data.order.legal_profile_id !== null) {
        const legalProfileRequest = await getLegalProfile(
            orderRequest.data.order.legal_profile_id
        )
        legalProfile.value = legalProfileRequest.data.legal_profile

        if (
            legalProfileRequest.data.legal_profile.company_name !==
            orderRequest.data.order.account_title
        )
            dataChanged.value = true
        else dataChanged.value = false
    }

    const platformRequest = await getPlatform(
        order.value.platform_name as string
    )
    platform.value = platformRequest.data.platform
}

function openCancelOrderModal() {
    statusModalTitle.value = 'modal_title_cancel_order'
    changeToStatus.value = OrderStatus.CANCELED
    cancelOrderModalOpen.value = true
}

function openSetOrderStatusAcceptModal() {
    statusModalTitle.value = 'modal_title_accept_order'
    changeToStatus.value = OrderStatus.ACCEPTED
    setOrderStatusModalOpen.value = true
}

function openSetOrderStatusPendingModal() {
    statusModalTitle.value = 'modal_title_set_order_status_to_pending'
    changeToStatus.value = OrderStatus.PENDING
    setOrderStatusModalOpen.value = true
}

function openSetOrderStatusPaidModal() {
    statusModalTitle.value = 'modal_title_set_order_status_to_paid'
    changeToStatus.value = OrderStatus.PAID
    setOrderStatusModalOpen.value = true
}

function closeSetOrderStatusModal() {
    setOrderStatusModalOpen.value = false
}

async function setOrderStatus(order: Order) {
    if (!order.order_uid) {
        return
    }

    order.status = changeToStatus.value
    await update(
        i18n.t('toast_success_order_status_changed', {
            order: order.order_uid,
        })
    )

    setOrderStatusModalOpen.value = false
}

async function cancelOrder(orderToCancel: Order) {
    if (!orderToCancel.order_uid) {
        return
    }
    const apiCancelOrderResponse = await apiCancelOrder(orderToCancel.order_uid)
    toast.success(
        i18n.t('toast_success_cancel_order', {
            order: orderToCancel.order_uid,
        })
    )
    order.value = apiCancelOrderResponse.data.order

    cancelOrderModalOpen.value = false
}

function editOrder() {
    router.push({
        name: 'order.edit',
        params: { id: order.value?.order_uid },
    })
}

function toOrders() {
    router.push({ name: 'orders' })
}

const openSetOrderStatusPaidModalCondition = computed(() => {
    return (
        userStore.can('update_order') &&
        !!(order.value.order_uid && order.value.status === OrderStatus.PENDING)
    )
})

const openSetOrderStatusPendingModalCondition = computed(() => {
    return (
        userStore.can('update_order') &&
        !!(
            order.value.order_uid &&
            (order.value.status === OrderStatus.PAID ||
                order.value.status === OrderStatus.ACCEPTED ||
                order.value.status === OrderStatus.CANCELED)
        )
    )
})

const openSetOrderStatusCancelModalCondition = computed(() => {
    return (
        userStore.can('delete_order') &&
        !!(
            order.value.order_uid &&
            (order.value.status === OrderStatus.PENDING ||
                order.value.status === OrderStatus.PAID ||
                order.value.status === OrderStatus.ACCEPTED)
        )
    )
})

const openSetOrderStatusAcceptModalCondition = computed(() => {
    return (
        userStore.can('update_order') &&
        !!(order.value.order_uid && order.value.status === OrderStatus.PAID)
    )
})

const openChangeStatusDateModalCondition = computed(() => {
    return userStore.can('change_order_status_date') && !!order.value.order_uid
})

const showActionMenu = computed(() => {
    return (
        openSetOrderStatusPaidModalCondition.value ||
        openSetOrderStatusPendingModalCondition.value ||
        openSetOrderStatusCancelModalCondition.value ||
        openSetOrderStatusAcceptModalCondition.value ||
        openChangeStatusDateModalCondition.value
    )
})

const { ActionsMenu } = useActionsMenu(
    {
        id: 'order-actions-menu',
        align: 'right',
    },
    [
        {
            label: 'set_to_paid',
            icon: 'fa-hand-holding-dollar',
            color: 'text-primary-accent-600',
            action: openSetOrderStatusPaidModal,
            condition: () => openSetOrderStatusPaidModalCondition.value,
        },
        {
            label: 'set_to_pending',
            icon: 'fa-chair',
            color: 'text-gray-600',
            action: openSetOrderStatusPendingModal,
            condition: () => openSetOrderStatusPendingModalCondition.value,
        },
        {
            label: 'set_to_canceled',
            icon: 'fa-ban',
            color: 'text-danger-600',
            action: openCancelOrderModal,
            condition: () => order.value.status !== OrderStatus.CANCELED,
        },
        {
            label: 'set_to_accepted',
            icon: 'fa-square-check',
            color: 'text-primary-accent-600',
            action: openSetOrderStatusAcceptModal,
            condition: () => openSetOrderStatusAcceptModalCondition.value,
        },
        {
            label: 'change_order_status_date',
            icon: 'fa-calendar',
            color: 'text-gray-600',
            action: openChangeStatusDateModal,
            condition: () => openChangeStatusDateModalCondition.value,
        },
        {
            label: 'resend_mail',
            icon: 'fa-paper-plane',
            color: 'text-blue-600',
            action: openResendMailModal,
            condition: () => userStore.can('resend_order_email'),
        },
    ]
)

const changeOrderStatusDatePayload = ref<ChangeOrderStatusDatePayload>({
    status: null,
    date: null,
})

function openChangeStatusDateModal() {
    isModalOpen.value = true
    changeOrderStatusDatePayload.value = {
        status: null,
        date: null,
    }
    openModal(
        {
            id: 'change_order_status_date_modal',
            title: 'modal_title_change_order_status_date',
            open: isModalOpen,
            onCancel: () => {
                isModalOpen.value = false
            },
            onSubmit: changeOrderStatusDate,
        },
        () => {
            return h('div', null, [
                h(SelectField, {
                    name: 'status',
                    label: 'status',
                    help: 'help_order_status',
                    modelValue: changeOrderStatusDatePayload.value.status,
                    'onUpdate:modelValue': (value) =>
                        (changeOrderStatusDatePayload.value.status = value),
                    options: orderStatusOptions.value,
                    required: true,
                }),
                h(Datepicker, {
                    name: 'date',
                    label: 'date',
                    help: 'help_order_status_date',
                    modelValue: changeOrderStatusDatePayload.value.date,
                    required: true,
                    'onUpdate:modelValue': (value) =>
                        (changeOrderStatusDatePayload.value.date = value),
                }),
            ])
        },
        {
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
            submitButtonDisabled: disableChangeOrderStatusDateButton,
        }
    )
}

const disableChangeOrderStatusDateButton = computed(
    () =>
        !(
            changeOrderStatusDatePayload.value.date &&
            changeOrderStatusDatePayload.value.status
        )
)

const orderStatusOptions = ref<
    {
        label: string | null
        value: number | string | null
    }[]
>([
    { label: 'paid', value: OrderStatus.PAID },
    { label: 'accepted', value: OrderStatus.ACCEPTED },
    { label: 'canceled', value: OrderStatus.CANCELED },
])

async function changeOrderStatusDate() {
    if (!order.value.order_uid) {
        return
    }
    const response = await changeOrderStatusDateApi(
        order.value.order_uid,
        changeOrderStatusDatePayload.value
    )

    order.value = response.data.order

    isModalOpen.value = false
    await loadOrder()
}

async function confirmSignature() {
    isModalOpen.value = true
    openModal(
        {
            id: 'order_confirm_signature',
            title: 'modal_title_confirm_signature',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: async () => {
                order.value.has_signature = true
                await saveOrder()
                await loadOrder()
                isModalOpen.value = false
            },
        },
        () => h('p', i18n.t('modal_text_confirm_signature')),
        {
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
        }
    )
}

async function regenerateSignature() {
    isModalOpen.value = true
    openModal(
        {
            id: 'order_regenerate_signature',
            title: 'modal_title_regenerate_signature',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: async () => {
                order.value.regenerate_signatures = true
                await saveOrder()
                await loadOrder()
                isModalOpen.value = false
            },
        },
        () => h('p', i18n.t('modal_text_regenerate_signature')),
        {
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

async function saveOrder() {
    await updateOrder(order.value)
    toast.success(
        i18n.t('toast_success_order_updated', {
            order: order.value.order_uid,
        })
    )
}

const signaturesToRegenerate = computed(() => {
    if (order.value.emission_signatures.length > 0) {
        return !order.value.emission_signatures.filter(
            (signature) => signature.signed_at === null
        ).length
    }
    return false
})

const orderEmailOptions: {
    label: string
    value: string
}[] = [
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.CONSUMER_INFORMATION.toUpperCase()
        ),
        value: PlatformEmailTemplateType.CONSUMER_INFORMATION,
    },
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.PAYMENT_DETAILS.toUpperCase()
        ),
        value: PlatformEmailTemplateType.PAYMENT_DETAILS,
    },
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.ACCEPTANCE_NOTICE.toUpperCase()
        ),
        value: PlatformEmailTemplateType.ACCEPTANCE_NOTICE,
    },
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.BANK_ACCOUNT_INFORMATION_REMINDER.toUpperCase()
        ),
        value: PlatformEmailTemplateType.BANK_ACCOUNT_INFORMATION_REMINDER,
    },
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.FEEDBACK_REVIEW.toUpperCase()
        ),
        value: PlatformEmailTemplateType.FEEDBACK_REVIEW,
    },
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.KYC_REMINDER.toUpperCase()
        ),
        value: PlatformEmailTemplateType.KYC_REMINDER,
    },
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.NO_IBAN_REMINDER.toUpperCase()
        ),
        value: PlatformEmailTemplateType.NO_IBAN_REMINDER,
    },
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.PAYMENT_CONFIRMATION.toUpperCase()
        ),
        value: PlatformEmailTemplateType.PAYMENT_CONFIRMATION,
    },
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.PAYMENT_REMINDER.toUpperCase()
        ),
        value: PlatformEmailTemplateType.PAYMENT_REMINDER,
    },
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.SIGNATURE_REMINDER.toUpperCase()
        ),
        value: PlatformEmailTemplateType.SIGNATURE_REMINDER,
    },
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.SIGNATURES_REGENERATED.toUpperCase()
        ),
        value: PlatformEmailTemplateType.SIGNATURES_REGENERATED,
    },
    {
        label: i18n.t(
            'EMAIL_TEMPLATE_' +
                PlatformEmailTemplateType.SIGNATURE_SUCCESSFUL.toUpperCase()
        ),
        value: PlatformEmailTemplateType.SIGNATURE_SUCCESSFUL,
    },
]

function openResendMailModal() {
    isModalOpen.value = true

    openModal(
        {
            id: 'order_resend_mail',
            title: 'modal_title_resend_mail',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: handleResendEmail,
        },
        () => {
            return h('div', null, [
                h(SelectField, {
                    name: 'email',
                    label: 'email',
                    help: 'help_resend_mail',
                    modelValue: emailToResend.value,
                    'onUpdate:modelValue': (value) => {
                        emailToResend.value = value
                    },
                    options: orderEmailOptions,
                    required: true,
                }),
            ])
        },
        {
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

async function handleResendEmail() {
    const resendEmailResponse = await resendEmail(
        order.value.order_uid as string,
        emailToResend.value as string
    )
    toast.success(resendEmailResponse.data.message)
    emailToResend.value = null
    isModalOpen.value = false
}

watch(
    () => route.params.id,
    async () => {
        await loadOrder()
    }
)

onMounted(async () => {
    loadOrder()
})
</script>
