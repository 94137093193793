/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
import { de } from '@src/utils/lang/de';
import { en } from '@src/utils/lang/en';
import Cookies from 'js-cookie';
import { createI18n, Path } from 'vue-i18n';

const messages = {
    en: en,
    de: de,
}

// Retrieve the language cookie
const language = Cookies.get('language') || import.meta.env.VITE_DEFAULT_LANG;

export const i18n = createI18n({
    legacy: false,
    locale: language, // Set the locale based on the cookie or default language
    fallbackLocale: import.meta.env.VITE_FALLBACK_LANG,
    globalInjection: true,
    messages: messages,
    missing: (locale: string, key: Path) => {
        if (key.length === 0) {
            return '-'
        }
        return key
    },
});
