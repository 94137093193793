import { useTable } from '@composables/common'
import { OrderCertificate } from '@src/types'
import { useUserStore } from '@src/utils/stores/user'
import { Ref, computed } from 'vue'

export function useOrderCertificateTable(
    orderCertificate: Ref<OrderCertificate[]>
) {
    const userStore = useUserStore()

    const total = computed(() => {
        return orderCertificate.value.length
    })
    const { TableWrapper: OrderCertificateTable } = useTable({
        tableProps: {
            data: orderCertificate,
            total: total,
            label: 'order_certificate',
            id: 'certificate_table',
            help: 'help_order_certificate_table',
            columns: [
                {
                    label: 'id',
                    key: 'id',
                    url: documentURL,
                    targetBlank: true,
                    sorting: false,
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'name',
                    key: 'name',
                    url: documentURL,
                    targetBlank: true,
                    sorting: false,
                    copy: true,
                },
            ],
            actions: [
                {
                    action: handleOpenInNewTab,
                    icon: 'fa-eye',
                    title: 'view_document',
                },
            ],
        },
    })

    function documentURL(document: OrderCertificate) {
        return document.url
    }

    function handleOpenInNewTab(rowData: OrderCertificate) {
        const url = rowData.url
        window.open(url, '_blank')
    }

    return {
        OrderCertificateTable,
    }
}
